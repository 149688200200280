import { render, staticRenderFns } from "./BlueRevButton.vue?vue&type=template&id=01741803&scoped=true"
import script from "./BlueRevButton.vue?vue&type=script&lang=js"
export * from "./BlueRevButton.vue?vue&type=script&lang=js"
import style0 from "./BlueRevButton.vue?vue&type=style&index=0&id=01741803&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01741803",
  null
  
)

export default component.exports