import axios from 'axios'
import utils from 'libs/utils.js'

const client = axios.create();

export const request = (options, self, showMessages=false, messageSuccess='Operation succesfully') => {
  const setLoaded = value => {
    if(self && 'loaded' in self)
      self.loaded = value
  }
  const onSuccess = response => {
    if(
      showMessages &&
      self &&
      'status' in response.data &&
      response.data.status != "success"
    )
      self.$notify({text: "An error has occurred"})
    setLoaded(false)
    return response
  }
  const onError = error => {
    if(showMessages && self)
      self.$notify({text: "An error has occurred"})
    setLoaded(false)
    throw error
  }
  const generalOptions = {
    method: ('method' in options && options.method) ? options.method : 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  }

  /*if(process.env.NODE_ENV === 'development')
    generalOptions.headers['X-CSRF-Token'] = csrfToken || null*/

  setLoaded(true)

  return client(Object.assign({}, generalOptions, options))
    .then(onSuccess)
    .catch(onError)
}

const typePayment = (callDate, paymentDate) => {
  let call = utils.formatDate(callDate, 'DD/MM/YYYY')
  let payment = utils.formatDate(paymentDate, 'DD/MM/YYYY')
  return call != payment ? 'scheduled' : 'payment'
}

const completeAddress = (address, postcode, regionState, city) => {
  let myAddress = [
    address,
    city,
    regionState,
    postcode
  ].filter(Boolean)

  return myAddress.join(", ")
}

const cardType = (type, number) => {
  let cardType = type ? type : ""
  let cardNumber = number && number.slice(-4) ? number.slice(-4) : ""
  return cardType && cardNumber ? cardType + "/" + cardNumber : cardType + cardNumber
}

const parseErrors = errors => {
  return [].concat.apply(
    [],
    Object.values(errors).map(k =>
      `${Object.values(k)}: ${Object.keys(k)}`.replace(/_/g, ' ')
    )
  )
}

const parseScheduledErrors = (errors) => {
  return errors
    .map((i) => ('error_message' in i && i.error_message) ? i.error_message : null)
    .filter(n => n)
}

const parseMonth = (date) => {
  return date ? date.substring(0, 2) : null
}

const parseYear = (date) => {
  return date ? '20' + date.substring(2, 4) : null
}

const parsePhone = (number) => {
  number = number.replace('+', '')
  if(number.startsWith('00'))
    number = number.replace('00', '')
  else if(number.startsWith('0'))
    number = number.replace('0', '44')
  return number
}

const validCardLength = number => {
  if(number && typeof number === 'string') {
    const numberLength = number.replace(/\s/g,'').replace(/-/gi, '').length
    return numberLength > 13 && numberLength < 20
      ? true
      : false
  }
}

const validExpiryDateLength = number => {
  if(number && typeof number === 'string') {
    const numberLength = number.replace(/\s/g,'').length
    return numberLength == 4
      ? true
      : false
  }
}

const validCVVLength = number => {
  if(number && typeof number === 'string') {
    const numberLength = number.replace(/\s/g,'').length
    return numberLength > 2 && numberLength < 5
      ? true
      : false
  }
}

export default {
  typePayment: typePayment,
  completeAddress: completeAddress,
  cardType: cardType,
  parseErrors: parseErrors,
  parseScheduledErrors: parseScheduledErrors,
  parseMonth: parseMonth,
  parseYear: parseYear,
  parsePhone: parsePhone,
  validCardLength: validCardLength,
  validExpiryDateLength: validExpiryDateLength,
  validCVVLength: validCVVLength
}
