<template>
  <div v-if="namespace === 'tokenise' &&  !(errorMode && paymentErrors.length > 0)">
    <TokenizationStatus
        :status="data"
    />
  </div>
  <div v-else>
    <LoadingPG v-show="loaded" />
    <div v-if="paymentErrors.length == 0">
      <div v-for="(statuses, idx1) in data" :key="`statuses_${idx1}`" class="StatusContainer">
        <div v-for="(currency, idx2) in statuses" :key="`currencies_${idx1}_${idx2}`">
          <PaymentStatus
            :color="currency.color"
            :pic="currency.pic"
            :amount="amountFix(currency.amount)"
            :currency="idx2"
            :status="currency.message"
            :currencySymbols="currencySymbols"
          />
          <TokenText
            v-for="(token, idx) in currency.tokens"
            :key="idx"
            :amount="token.amount"
            :currency="token.currency"
            :token="token.token"
            :bankAuth="token.bank_auth"
            :pgAuth="token.pg_auth"
            :currencySymbols="currencySymbols"
            :tokens="tokenItems"
          />
        </div>
      </div>
    </div>

    <div v-if="errorMode && paymentErrors.length > 0">
      <p class="label" tabindex="0">Reason for Decline</p>
      <ErrorMessage v-for="(error, index) in paymentErrors" :key="index">{{ error }}</ErrorMessage>
    </div>

    <InputEmailSMS
      v-if="sendOptions.length > 0"
      label="Send Confirmation"
      :apiData="sendApiData"
      :sendOptions="sendOptions"
      :endPoint="receiptURL"
    />

    <SendNotes
      title="Feedback"
      :apiData="sendApiData"
      :endPoint="feedbackURL"
    />
  </div>
</template>

<script>
  import IconDone from 'assets/img/icon-done@2x.png'
  import IconBack from 'assets/img/icon-back@2x.png'
  import BlueRevButton from 'components/PayGuard/Buttons/BlueRevButton.vue'
  import PinkButton from 'components/PayGuard/Buttons/PinkButton.vue'

  import PaymentStatus from 'components/PayGuard/PaymentStatus.vue'
  import SendReference from 'components/PayGuard/SuccessScreen/SendReference.vue'

  import InputEmailSMS from 'components/PayGuard/Form/InputEmailSMS.vue'
  import SendNotes from 'components/PayGuard/SuccessScreen/SendNotes.vue'
  import StatusCircle from 'components/PayGuard/StatusCircle.vue'
  import TokenText from 'components/PayGuard/SuccessScreen/TokenText.vue'
  import ErrorMessage from 'components/PayGuard/Errors/ErrorMessage.vue'
  import TokenizationStatus from 'components/PayGuard/TokenizationStatus.vue'

  import LoadingPG from 'components/PayGuard/LoadingPG.vue'

  export default {
    props: {
      stateLink: {
        type: String,
        required: false,
        default: 'paymentSuccess'
      },
      data: {
        type: Object,
        default: () => {}
      },
      groupId: {
        type: [String, Number],
        default: null
      },
      paymentErrors: {
        type: Array,
        default: () => []
      },
      agentCurrencies: {
        type: Array,
        default: () => []
      },
      namespace:{
        type: String,
        default: ''
      }
    },
    data() {
      return {
        IconDone: IconDone,
        IconBack: IconBack,
        sendOptions: ['Email', 'SMS'],
        transferOptions: [],
        loaded: false,
        triggerEndCall: false,
        tokenItems: [
          { prop: 'token', name: 'Token', permission: null},
          { prop: 'bankAuth', name: 'Bank Auth', permission: 'payment:post_payment:bankcode'},
          { prop: 'pgAuth', name: 'PayGuard Auth', permission: 'payment:post_payment:authcode'}
        ]
      }
    },
    components: {
      PaymentStatus,
      InputEmailSMS,
      SendNotes,
      SendReference,
      BlueRevButton,
      PinkButton,
      StatusCircle,
      ErrorMessage,
      LoadingPG,
      TokenText,
      TokenizationStatus
    },
    computed: {
      receiptURL() {
        return `${basePath}payments/api/key/postpayment/send-paymentlink-receipt`
      },
      feedbackURL() {
        return `${basePath}payments/api/key/postpayment/add-feedback`
      },
      statusesColumns() {
        let cols = 12 / Object.keys(this.statusesGrouped).length;
        return "col-lg-" + cols + " col-md-" + cols + " col-sm-" + cols;
      },
      showOptions() {
        return this.groupId ? true : false;
      },
      paymentText() {
        switch (this.stateLink) {
          case "paymentSuccess":
            return "completed successfully";
          case "paymentScheduled":
            return "scheduled";
          case "paymentFailed":
            return "declined";
        }
      },
      errorMode() {
        return this.stateLink == "paymentFailed" ? true : false
      },
      sendApiData() {
        return {
          group_id: this.groupId,
          paylink_token: token,
          is_error: this.errorMode,
          error_message: this.paymentErrors.length > 0 ? this.paymentErrors[0] : ''
        }
      },
      currencySymbols() {
        let currencySymbols = {}
        if(this.agentCurrencies.length > 0)
          this.agentCurrencies.forEach(i => {
            currencySymbols[i.code] = i.symbol
          })
        return currencySymbols
      }
    },
    methods: {
      amountFix(value) {
        return value.toFixed(2);
      }
    }
  }
</script>

<style scoped lang="less">
  @import "../payments/css/payguard-style.less";

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @import "../payments/css/payguard-style-ie.less";
  }
  
  .StatusContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(172px, 345px));
    grid-gap: 20px;
    width: 100%;
    justify-content: center;
  }
</style>