const dateFormat = require("date-fns/format");
const { formatToTimeZone } = require("date-fns-timezone");

const formatDate = (inputFormat, format = "DD/MM/YYYY HH:mm") => {
  if (inputFormat) {
    const inputDate = new Date(inputFormat);
    return dateFormat(inputDate, format);
  }
  return null;
};

const formatTime = (d) => {
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);
  return ((h > 0 ? h + ":" + (m < 10 ? "0" : "") : "") + m + ":" + (s < 10 ? "0" : "") + s);
};

const utcToTimezone = (
  inputFormat,
  format = "DD/MM/YYYY HH:mm",
  zone = "Europe/London"
) => {
  const inputDate = new Date(inputFormat);
  return formatToTimeZone(inputDate, format, { timeZone: zone });
};

const dateToISO = (date) => {
  if(date)
    return new Date(date).toISOString()
}

const some = values => {
  return values.some(item => {
    if (item !== "" && item !== null) return true;
    return false;
  });
};

const every = values => {
  return values.every(item => {
    if (item !== "" && item !== null) return true;
    return false;
  });
};

const sortList = l => {
  return l.sort((a, b) => {
    return a > b ? 1 : -1
  })
}

const sortListObjects = (items, field) => {
  return items.sort((a,b) =>
    (a[field].toUpperCase() > b[field].toUpperCase())
      ? 1
      : ((b[field].toUpperCase() > a[field].toUpperCase()) ? -1 : 0)
    )
}

const capitalize = string => {
  if(string) {
    string = string.toLowerCase()
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}

const ordinal = day => {
  switch(day.toString()) {
    case '1': return '1st'
    case '2': return '2nd'
    case '3': return '3rd'
    default: return `${day}th`
  }
}

const itemInObject = (obj, key) => {
  return key in obj && obj[key] != null
}

const pickFromObject = (obj, keys) => {
  return Object.assign({}, ...keys.map(key => ({ [key]: obj[key] })));
};

const pickFromListByValue = (list, value) => {
  return list.filter(i => i.value.toString() == value.toString())
};

const getNameFromListByValue = (list, value) => {
  const obj = pickFromListByValue(list, value)
  return Array.isArray(obj) && obj.length > 0 && 'name' in obj[0]
    ? obj[0].name
    : ''
}

const forceDownload = (data, filename, mime) => {
  var blob = new Blob([data], { type: mime || "application/octet-stream" });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var blobURL = window.URL.createObjectURL(blob);
    var tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};

const crypto = require("crypto");
Object.defineProperty(global.self, "crypto", {
  value: {
    getRandomValues: arr => crypto.randomBytes(arr.length)
  }
});

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};


const makeid = (length) => {
  let result = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length
  for (var i = 0; i < length; i++)
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  return result
}

const multiselectValues = l => l ? l.map(i => i.value) : []

const multiselectNames = l => l ? l.map(i => i.name) : []

const selectValue = l => {
  if(l){
    return l.value;
  }
  return null;
}

const intersection = (arrays) => {
  if (arrays.length === 0)
    return []
  return arrays[0].filter(item => arrays.every(array => array.includes(item)))
}

const hasValue = field => {
  return (
    typeof field !== 'undefined' &&
    field !== null &&
    field !== undefined &&
    field.toString().length !== 0
  )
    ? true
    : false
}

const list2objects = l => {
  return typeof l !== 'undefined' && l && l.length > 0
    ? l
      .filter(i => hasValue(i[0]) && hasValue(i[1]))
      .map(i => { return {name: i[1], value: i[0]} })
    : []
}

const dateRangeValues = (l, format='YYYY-MM-DD') => {
  return {
    datetimeFrom: l && l.length > 0 ? formatDate(l[0], format) : '',
    datetimeTo: l && l.length > 1 ? formatDate(l[1], format) : '',
  }
}

const userLink = id => {
  return id
    ? `${basePath}account/user#/${id}/view`
    : ''
}

const accountLink = id => {
  return id
    ? `${basePath}account/account#/${id}/view`
    : ''
}

const memberLink = (text, is_group_admin, customer_id, user_id) => {
  return {
    text: text,
    link: is_group_admin == true
      ? accountLink(customer_id)
      : userLink(user_id)
  }
}

const validateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

function replaceTag(tag) {
  let tagsToReplace = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;'
  }
  return tagsToReplace[tag] || tag
}

const encodeHTML = str => {
  return typeof str === 'string'
    ? str.replace(/[&<>]/g, replaceTag)
    : str
}

const sortByName = _ => _.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

export default {
  formatDate: formatDate,
  formatTime: formatTime,
  utcToTimezone: utcToTimezone,
  dateToISO: dateToISO,
  some: some,
  every: every,
  capitalize: capitalize,
  ordinal: ordinal,
  itemInObject: itemInObject,
  pickFromObject: pickFromObject,
  pickFromListByValue: pickFromListByValue,
  getNameFromListByValue: getNameFromListByValue,
  forceDownload: forceDownload,
  uuidv4: uuidv4,
  makeid: makeid,
  multiselectValues: multiselectValues,
  multiselectNames: multiselectNames,
  selectValue:selectValue,
  intersection: intersection,
  hasValue: hasValue,
  list2objects: list2objects,
  sortList: sortList,
  sortListObjects: sortListObjects,
  dateRangeValues: dateRangeValues,
  userLink: userLink,
  accountLink: accountLink,
  memberLink: memberLink,
  validateEmail: validateEmail,
  delay: delay,
  encodeHTML: encodeHTML,
  sortByName: sortByName
};
