var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['form-group-pg', _vm.position == 'center' || _vm.position == 'right' ? 'ml-1' : '']},[(_vm.label != '')?_c('label',{staticClass:"input-label"},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):(_vm.labelGap)?_c('label',{staticClass:"input-label"},[_vm._v(" ")]):_vm._e(),_vm._v(" "),(this.$props.type in _vm.options)?_c('cleave',{class:[_vm.position, 'input', _vm.error ? 'error' : '', _vm.disabled? 'disabled':'', _vm.bold ? 'bold':''],attrs:{"placeholder":_vm.placeholder,"options":_vm.getOptions(),"name":_vm.name,"readonly":_vm.readonly},nativeOn:{"blur":function($event){return _vm.addDecimals.apply(null, arguments)},"keyup":function($event){return _vm.keyup.apply(null, arguments)}},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],ref:"mainInput",class:[
      _vm.position,
      'input',
      _vm.error ? 'error' : '',
      _vm.disabled ? 'disabled' : '',
      _vm.bold ? 'bold' : '',
      _vm.paste ? 'bigger-padding' : '',
      _vm.copy ? 'bigger-padding' : ''
    ],attrs:{"placeholder":_vm.placeholder,"name":_vm.name,"maxlength":this.$props.maxLength,"type":"text","readonly":_vm.readonly},domProps:{"value":(_vm.input)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnter.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.input=$event.target.value}},nativeOn:{"blur":function($event){return _vm.addDecimals.apply(null, arguments)}}}),_vm._v(" "),(_vm.paste)?_c('img',{class:[ _vm.showClipboard ? 'iconClipboard' : 'hide-icon'],attrs:{"src":_vm.IconPaste},on:{"click":_vm.pasteClipboard}}):_vm._e(),_vm._v(" "),(_vm.copy)?_c('img',{class:[ _vm.showClipboard ? 'iconClipboard' : 'hide-icon'],attrs:{"src":_vm.IconCopy},on:{"click":_vm.copyClipboard}}):_vm._e(),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }