<template>
  <div style="width: 500px;">
    <span>{{ item.address }}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
  }
}
</script>