<template>  
    <button :title="title" v-bind:class="['btn-blue', disabled? 'disabled':'']" @click="onClick" v-bind:style="{ 'background-image': 'url(' + iconName + ')' }">
      <span>
        <slot></slot>
      </span>
    </button>
</template>

<script>
  export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        iconName: {
          type: String,
          default: ''
        },
        title: {
          type: String,
          default: ''
        }
    },
    methods: {
      onClick () {
        this.$emit('click');
      }
    }
  }
</script>

<style scoped lang="less">
@import '~styles/colors.less';
    
    .btn-blue {
        height: 40px;
        width: 100%;
        border: 1px solid @light-blue;
        border-radius: 3px;
        background-color: @light-blue;
        cursor: pointer;
        color: #FFFFFF;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: bold;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: 6px center;
        padding: 6px 8px 6px 16px;
        box-sizing: border-box;
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
            cursor: not-allowed;
        }
        & span{
            text-align: center;
        }
    }
</style>