<template>
  <div class="pt10 mb20">  
    <center>
      <div :class="'circle ' + color">
        <div :class="'circle pic ' + pic"></div>
      </div>
      <p class="money-header">{{myCurrencySymbol}}{{amount}}</p>
      <p class="payment-text">{{status}}</p>
    </center>
  </div>
</template>

<script>
  export default {
    props: {
      color: {
        default: 'green',
        type: String
      },
      amount: {
        default: '',
        type: String
      },
      currency: {
        default: '',
        type: String
      },
      status: {
        default: '',
        type: String
      },
      pic: {
        default: 'wallet',
        type: String
      },
      currencySymbols: {
        default: () => {},
        type: Object
      }
    },
    computed: {
      myCurrencySymbol() {
        if(this.currency && typeof this.currencySymbols[this.currency] != 'undefined')
          return this.currencySymbols[this.currency]
        return ''
      }
    }
  }
</script>

<style scoped lang="less">
  .circle{
    height: 80px;
    width: 80px;
    text-align: center;
    border-radius: 50%;
    display: table;
    &.pic{
      background-size: 50px 50px;
      display: table-cell;
      vertical-align: middle;
      background-repeat: no-repeat;
      background-position: center;
    }
    &.green{
      background: #66C400;
    }
    &.red{
      background: #D0021B;
    }
    &.wallet{
      background-image: url(~assets/img/icon-success-wallet@2x.png);
    }
    &.locked{
      background-image: url(~assets/img/icon-success-locked@2x.png);
    }
    &.calendar{
      background-image: url(~assets/img/icon-success-calendar@2x.png);
    }
  }

  .money-header{
    color: #2B295E;
    font-family: 'Open Sans', sans-serif;;
    font-size: 36px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-weight: 300;
  }

  .payment-text{
    color: #6a6a6a;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .pt10{
    padding-top: 10px;
  }

  .mb20{
    margin-bottom: 20px;
  }
</style>