import { render, staticRenderFns } from "./SendNotes.vue?vue&type=template&id=312a7273&scoped=true"
import script from "./SendNotes.vue?vue&type=script&lang=js"
export * from "./SendNotes.vue?vue&type=script&lang=js"
import style0 from "./SendNotes.vue?vue&type=style&index=0&id=312a7273&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "312a7273",
  null
  
)

export default component.exports