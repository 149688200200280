<template>
  <div v-bind:class="['form-group-pg', position == 'center' || position == 'right' ? 'ml-1' : '']">
    <label v-if="label != ''" class="input-label aa">{{label}}</label>
    <label v-else-if="labelGap" class="input-label">&nbsp;</label>
    <div class="select-wrap">
      <select
        :title="title"
        v-model="input"
        v-bind:class="[
          position,
          'select',
          error ? 'error' : '',
          disabled ? 'disabled' : '',
          italic ? 'italic' : ''
        ]"
        :style="style"
        >
        <option v-if="nullable" :value="null">Choose an Option</option>
        <option v-for="(option, index) in options" :value="option.value || option" :key="'opt' + index">
          {{option.name || option}}
        </option>
        <optgroup v-for="(option, index) in optgroups" :label="option.name" :key="'group' + index">
          <option v-for="(item, idx) in option.items" :value="item.value || item" :key="'groupopt' + idx">
            {{item.name || item}}
          </option>
        </optgroup>
      </select>
    </div>
    <div class="error-message" v-if="error">
      {{error_message}}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      labelGap: {
        type: Boolean,
        default: false
      },
      options: {
        type: Array,
        default: () => []
      },
      optgroups: {
        type: Array,
        default: () => []
      },
      width: {
        type: Number,
        default: null
      },
      position: {
        type: String,
        default: ''
      },
      error_message: {
        type: String,
        default: 'Required'
      },
      error: {
        type: Boolean,
        default: false
      },
      nullable: {
        type: Boolean,
        default: true
      },
      value: {
        type: String,
        default: null
      },
      italic: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        input: this.value
      }
    },
    computed: {
      style() {
        return this.width
          ? {width: `${this.width}px`}
          : {}
      }
    },
    watch: {
      value: function(value) {
        this.input = value
      },
      input: function(value) {
        this.$emit("change", value)
        this.$emit("input", value)
      }
    }
  }
</script>

<style scoped lang="less">
@import '~styles/colors.less';

  .form-group-pg {
    margin-bottom: 10px;
    position: relative;
    & .select-wrap {
      margin: 0;
      padding: 0;
    }
    &.ml-1{
      margin-left: -1px;
    }
  }
  
  label.input-label {
    color: @dark-blue;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    width: 100%;
    float: left;
    padding: 10px 0px;
    margin: 0px !important;
  }

  .form-group-pg .select-wrap:before {
    background-repeat: no-repeat;
    content: "";
    display: block;
    height: 8px;
    left: auto;
    margin: auto;
    position: absolute;
    right: 10px;
    bottom: 15px;
    width: 14px;
    pointer-events: none;
    background-image: url(~assets/img/icon-down@2x.png);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .form-group-pg select.select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    background: rgba(255, 255, 255, 0);
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0), #e6f2ff);
    background: -o-linear-gradient(rgba(255, 255, 255, 0), #e6f2ff);
    background: -moz-linear-gradient(rgba(255, 255, 255, 0), #e6f2ff);
    background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%);
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    -ms-border-radius: 3px !important;
    -o-border-radius: 3px !important;
    border-radius: 3px;
    border: 1px solid #C7C7C7;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    height: 40px;
    padding: 4px 35px 4px 10px;
    width: 100%;
    color: @dark-blue;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
    &.italic {
      font-style: italic;
    }
    &.right {
      border-radius: 0px 3px 3px 0px !important;
    }
    &.left {
      border-radius: 3px 0px 0px 3px !important;
    }
    &.center {
      border-radius: 0px 0px 0px 0px !important;
    }
    &.error {
      border-color: #D0021B;
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
    &.grey {
      color: #c7c7c7;
    }
    &:focus {
        outline: none !important;
    }
  }
  
  .form-group-pg select.select::-ms-expand {
    display: none !important;
  }
  
  .error-message {
    height: 35px;
    width: 100px;
    border: 1px solid #D0021B;
    border-radius: 3px;
    background-color: #D0021B;
    -webkit-box-shadow: 0 -2px 6px 0 rgba(208, 2, 27, 0.3);
    box-shadow: 0 -2px 6px 0 rgba(208, 2, 27, 0.3);
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    text-align: center;
    padding: 9px 5px 8px 5px;
    position: absolute;
    top: -7px;
    left: 38%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 125%;
    &::after {
      height: 11px;
      width: 11px;
      background: #d0021b;
      content: '.';
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 28px;
      left: calc(~'50% - 5.5px');
      color: transparent;
    }
  }
</style>