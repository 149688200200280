<template>
  <div class="card-form">
    <div class="card-form__inner">
      <div v-if="allowed('payment:ccdetails:name')" class="card-input">
        <label for="cardNumber" class="card-input__label">Name</label>
        <InputField
          placeholder="Name on card"
          v-model="formData.card_holder"
          ref="name"
        />
      </div>

      <div class="card-form__address-address">
        <div v-if="allowed('payment:ccdetails:address')" class="card-input">
          <label for="cardNumber" class="card-input__label">Address</label>
          <InputGetAddress
            v-if="allowed('payment:ccdetails:addresslookup')"
            placeholder="Enter postcode followed by first line of UK address to search"
            type_api="paymentlink-"
            @addressData="addressData"
          />
          <InputField
            v-else
            placeholder="Address the card is registered to"
            v-model="formData.address1"
          />
        </div>
      
        <div class="card-form__address-grid">
          <div v-if="allowed('payment:ccdetails:address')" class="card-input">
            <InputField
              placeholder="City"
              v-model="formData.town"
            />
          </div>
          <div v-if="allowed('payment:ccdetails:address')" class="card-input">
            <InputField
              placeholder="Region/State"
              v-model="formData.state"
            />
          </div>
        </div>

        <div class="card-form__address-grid">
          <div v-if="allowed('payment:ccdetails:address')" class="card-input">
            <InputField
              placeholder="Postal Code"
              v-model="formData.postcode"
            />
          </div>
          <div v-if="allowed('payment:ccdetails:address')" class="card-input">
            <SelectField
              v-model="formData.country"
              :nullable="false"
              :options="countriesList"
            />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  import InputField from 'components/PayGuard/Form/InputField.vue'
  import InputGetAddress from 'components/PayGuard/Form/InputGetAddress.vue'
  import SelectField from 'components/PayGuard/Form/SelectField.vue'
  import countryFile from 'libs/countries.js'

  export default {
    props: {
      permissions: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        formData: {
          country: 'GB (Great Britain)'
        },
        countriesList: countryFile,
      }
    },
    components: {
      InputField,
      InputGetAddress,
      SelectField
    },
    mounted() {
      this.$refs.name.setFocus()
    },
    computed: {
      allowedBrowser() {
        return (
          this.$browserDetect.isChrome ||
          this.$browserDetect.isFirefox ||
          this.$browserDetect.isOpera ||
          this.$browserDetect.isSafari ||
          this.$browserDetect.isEdge
        )
      }
    },
    watch: {
      'formData.card_holder'() {
        return this.$emit("customerData", this.customerData())
      },
      'formData.address1'() {
        return this.$emit("customerData", this.customerData())
      },
      'formData.town'() {
        return this.$emit("customerData", this.customerData())
      },
      'formData.state'() {
        return this.$emit("customerData", this.customerData())
      },
      'formData.postcode'() {
        return this.$emit("customerData", this.customerData())
      },
      'formData.country'() {
        return this.$emit("customerData", this.customerData())
      }
    },
    methods: {
      customerData() {
        return {
          card_holder: this.formData.card_holder,
          address1: this.formData.address1,
          town: this.formData.town,
          state: this.formData.state,
          postcode: this.formData.postcode,
          country: this.formData.country.split(" ")[0]
        }
      },
      addressData(value) {
        Vue.set(
          this.formData,
          'address1',
          'line_1' in value && value['line_1'] ? value['line_1'] : ''
        )

        Vue.set(
          this.formData,
          'postcode',
          'postcode' in value && value['postcode'] ? value['postcode'] : ''
        )

        Vue.set(
          this.formData,
          'town',
          'town_or_city' in value && value['town_or_city'] ? value['town_or_city'] : ''
        )

        Vue.set(
          this.formData,
          'state',
          'county' in value && value['county'] ? value['county'] : ''
        )
      },
      allowed(permission) {
        return (permission && this.permissions.includes(permission))
      }
    }
  }
</script>

<style scoped lang="less">
  @import '~styles/colors.less';

  /deep/ .input {
    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35) !important;
    }
  }

  body {
    background: #ddeefc;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
  }
  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }

  .card-form {
    padding: 10px 15px 0px 15px;
    max-width: 570px;
    margin: auto;
    width: 100%;

    @media screen and (max-width: 576px) {
      margin: 0 auto;
    }

    &__address-address {
      padding-bottom: 10px;
      padding-top: 10px;

      & .card-input {
        margin-bottom: 0;
      }

    }

    &__address-grid {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr);

      & .card-input {
        margin-bottom: 0 !important;
      }
    }

    &__inner {
      background: #fff;
      padding: 0px 35px 0px 35px;
      padding-top: 0px;

      @media screen and (max-width: 480px) {
        padding: 25px;
      }
      @media screen and (max-width: 375px) {
        padding: 0px;
      }
    }

    &__row {
      display: flex;
      align-items: flex-start;
      @media screen and (max-width: 480px) {
        flex-wrap: wrap;
      }
    }

    &__col {
      flex: auto;
      margin-right: 35px;

      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 480px) {
        margin-right: 0;
        flex: unset;
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.-cvv {
        max-width: 150px;
        @media screen and (max-width: 480px) {
          max-width: initial;
        }
      }
    }

    &__group {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .card-input__input {
        flex: 1;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__button {
      width: 100%;
      height: 55px;
      background: @pink;
      border: none;
      border-radius: 3px;
      font-size: 22px;
      font-weight: 500;
      font-family: "Open Sans", sans-serif;
      color: #fff;
      margin: 45px auto 0px auto;
      cursor: pointer;
      position: relative;
      max-width: 160px;
      text-align: center;
      padding: 5px 10px 5px 20px;
      display: block;

      @media screen and (max-width: 480px) {
        margin: 40px auto 0px auto;
      }
      
      &:before {
        font-family: "Font Awesome 5 Free"; 
        font-weight: 900; 
        content: "\f058";
        position: absolute;
        left: 15px
      }
    }

    &__button-disabled {
      opacity: 0.4;
    }
  }

  .card-item {
    max-width: 430px;
    height: 270px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
    width: 100%;

    @media screen and (max-width: 480px) {
      max-width: 310px;
      height: 220px;
      width: 90%;
      margin-top: 20px;
    }

    @media screen and (max-width: 375px) {
      height: 180px;
      width: 100%;
    }

    &.-active {
      .card-item__side {
        &.-front {
          transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
        }
        &.-back {
          transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
          // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
        }
      }
    }

    &__focus {
      position: absolute;
      z-index: 3;
      border-radius: 5px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
      opacity: 0;
      pointer-events: none;
      overflow: hidden;
      border: 2px solid rgba(255, 255, 255, 0.65);

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: rgb(8, 20, 47);
        height: 100%;
        border-radius: 5px;
        filter: blur(25px);
        opacity: 0.5;
      }

      &.-active {
        opacity: 1;
      }
    }

    &__side {
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.3);
      transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
      transform-style: preserve-3d;
      transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
      backface-visibility: hidden;
      height: 100%;

      &.-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
        z-index: 2;
        padding: 0;
        // background-color: #2364d2;
        // background-image: linear-gradient(
        //   43deg,
        //   #4158d0 0%,
        //   #8555c7 46%,
        //   #2364d2 100%
        // );
        height: 100%;

        .card-item__cover {
          transform: rotateY(-180deg)
        }
      }
    }
    &__bg {
      max-width: 100%;
      display: block;
      max-height: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    &__cover {
      height: 100%;
      position: absolute;
      height: 100%;
      background-image: linear-gradient(#2B295E, #BF087F);
      left: 0;
      top: 0;
      width: 100%;
      border-radius: 15px;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(6, 2, 29, 0.3);
      }
    }

    &__top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 40px;
      padding: 0 10px;

      @media screen and (max-width: 480px) {
        margin-bottom: 25px;
      }
      @media screen and (max-width: 375px) {
        margin-bottom: 15px;
      }
    }

    &__chip {
      width: 60px;
      @media screen and (max-width: 480px) {
        width: 50px;
      }
      @media screen and (max-width: 375px) {
        width: 40px;
      }
    }

    &__type {
      height: 45px;
      position: relative;
      display: flex;
      justify-content: flex-end;
      max-width: 100px;
      margin-left: auto;
      width: 100%;

      @media screen and (max-width: 480px) {
        height: 40px;
        max-width: 90px;
      }
      @media screen and (max-width: 375px) {
        height: 30px;
      }
    }

    &__typeImg {
      max-width: 100%;
      object-fit: contain;
      max-height: 100%;
      object-position: top right;
    }

    &__info {
      color: #fff;
      width: 100%;
      max-width: calc(100% - 90px);
      padding: 10px 10px;
      font-weight: 500;
      display: block;

      cursor: pointer;

      @media screen and (max-width: 480px) {
        padding: 10px;
      }
    }

    &__holder {
      opacity: 0.7;
      font-size: 13px;
      margin-bottom: 6px;
      @media screen and (max-width: 480px) {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }

    &__wrapper {
      font-family: "Source Code Pro", monospace;
      padding: 25px 15px;
      position: relative;
      z-index: 4;
      height: 100%;
      text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
      user-select: none;
      @media screen and (max-width: 480px) {
        padding: 20px 10px;
      }
    }

    &__name {
      font-size: 18px;
      line-height: 1;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }
    &__nameItem {
      display: inline-block;
      min-width: 8px;
      position: relative;
    }

    &__number {
      font-weight: 500;
      line-height: 1;
      color: #fff;
      font-size: 27px;
      margin-bottom: 35px;
      display: inline-block;
      padding: 10px 15px;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        font-size: 21px;
        margin-bottom: 15px;
        padding: 10px 10px;
      }

      @media screen and (max-width: 375px) {
        font-size: 19px;
        margin-bottom: 10px;
        padding: 10px 10px;
      }
    }

    &__numberItem {
      width: 16px;
      display: inline-block;
      &.-active {
        width: 30px;
      }

      @media screen and (max-width: 480px) {
        width: 13px;

        &.-active {
          width: 16px;
        }
      }

      @media screen and (max-width: 375px) {
        width: 12px;

        &.-active {
          width: 8px;
        }
      }
    }

    &__content {
      color: #fff;
      display: flex;
      align-items: flex-start;
    }

    &__date {
      flex-wrap: wrap;
      font-size: 18px;
      margin-left: 0px;
      padding: 10px 0 0 0;
      display: inline-flex;
      width: 80px;
      white-space: nowrap;
      flex-shrink: 0;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }

    &__dateItem {
      position: relative;
      span {
        width: 22px;
        display: inline-block;
      }
    }

    &__dateTitle {
      opacity: 0.7;
      font-size: 13px;
      width: 100%;
      margin-bottom: 3px;

      @media screen and (max-width: 480px) {
        font-size: 12px;
      }
    }
    &__band {
      background: rgba(0, 0, 19, 0.8);
      width: 100%;
      height: 50px;
      margin-top: 30px;
      position: relative;
      z-index: 2;
      @media screen and (max-width: 480px) {
        margin-top: 20px;
      }
      @media screen and (max-width: 375px) {
        height: 40px;
        margin-top: 10px;
      }
    }

    &__cvv {
      text-align: right;
      position: relative;
      z-index: 2;
      padding: 15px;
      .card-item__type {
        opacity: 0.7;
      }

      @media screen and (max-width: 375px) {
        padding: 10px 15px;
      }
    }
    &__cvvTitle {
      padding-right: 10px;
      font-family: "Source Code Pro", monospace;
      font-size: 15px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 5px;
    }
    &__cvvBand {
      font-family: "Source Code Pro", monospace;
      height: 45px;
      background: #fff;
      margin-bottom: 30px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      color: #1a3b5d;
      font-size: 18px;
      border-radius: 4px;
      box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

      @media screen and (max-width: 480px) {
        height: 40px;
        margin-bottom: 20px;
      }

      @media screen and (max-width: 375px) {
        margin-bottom: 15px;
      }
    }
  }

  .card-list {
    margin-bottom: -130px;
    padding-bottom: 90px;
    padding-left: 35px;
    padding-right: 35px;

    @media screen and (max-width: 480px) {
      margin-bottom: -120px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 120px;
    }
  }

  .card-input {
    margin-bottom: 20px;
    &__label {
      font-size: 16px;
      font-weight: 600;
      color: @dark-blue;
      width: 100%;
      display: block;
      user-select: none;
      line-height: 150%;
      font-family: "Open Sans", sans-serif;
    }
    &__input {
      width: 100%;
      height: 38px;
      border-radius: 3px;
      box-shadow: none;
      border: 1px solid #c7c7c7;
      transition: all 0.3s ease-in-out;
      font-size: 15px;
      padding: 4px 10px;
      background: none;
      color: #6a6a6a;
      font-family: "Open Sans", sans-serif;

      &:hover,
      &:focus {
        border-color: #c7c7c7;
      }

      &::placeholder {
        color: #c7c7c7; 
        font-style: italic;
      }

      &:focus {
        box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
      }
      &.-select {
        -webkit-appearance: none;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
        background-size: 12px;
        background-position: 90% center;
        background-repeat: no-repeat;
        padding-right: 30px;
      }
    }
  }

  .slide-fade-up-enter-active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
    position: relative;
  }
  .slide-fade-up-leave-active {
    transition: all 0.25s ease-in-out;
    position: absolute;
  }
  .slide-fade-up-enter {
    opacity: 0;
    transform: translateY(15px);
    pointer-events: none;
  }
  .slide-fade-up-leave-to {
    opacity: 0;
    transform: translateY(-15px);
    pointer-events: none;
  }

  .slide-fade-right-enter-active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
    position: relative;
  }
  .slide-fade-right-leave-active {
    transition: all 0.25s ease-in-out;
    position: absolute;
  }
  .slide-fade-right-enter {
    opacity: 0;
    transform: translateX(10px) rotate(45deg);
    pointer-events: none;
  }
  .slide-fade-right-leave-to {
    opacity: 0;
    transform: translateX(-10px) rotate(45deg);
    pointer-events: none;
  }
</style>