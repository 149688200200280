<template>
  <div>
    <LoadingPG v-show="loaded" />
    <div class="parent-box">
      <div class="position">
        <InputField
          :label="label"
          :placeholder="label"
          @input="setPostPaymentReference"
          :paste="true"
        />
        <SuccessField
          status="success"
          text="Reference has been applied successfully"
          style="position: absolute; bottom: 45px; right: 85px;"
          v-show="referenceSuccess"
        />
      </div>
      <div class="div-button">
        <BlueButton
          :icon-name="IconDone"
          @click="sendPostPaymentReference"
          :disabled="!postPaymentReference"
        >OK</BlueButton>
      </div>
    </div>
  </div>
</template>

<script>
  import IconDone from 'assets/img/icon-done@2x.png'
  import InputField from 'components/PayGuard/Form/InputField.vue'
  import BlueButton from 'components/PayGuard/Buttons/BlueButton.vue'
  import LoadingPG from 'components/PayGuard/LoadingPG.vue'
  import SuccessField from 'components/PayGuard/SuccessScreen/SuccessField.vue'

  import Vue from 'vue'
  import qs from 'qs'
  import { request } from 'pages/payments/payments/utils.payments.js'

  export default {
    data() {
      return {
        IconDone: IconDone,
        postPaymentReference: '',
        loaded: false,
        referenceSuccess: false
      }
    },
    props: {
      label: {
        type: String,
        default: 'Post-Order Reference'
      },
      endPoint: {
        type: String,
        required: true,
        default: ''
      },
      apiData: {
        type: Object,
        required: false,
        default: {}
      }
    },
    components: {
      InputField,
      BlueButton,
      SuccessField,
      LoadingPG
    },
    methods: {
      setPostPaymentReference: function(value) {
        this.postPaymentReference = value
      },
      sendPostPaymentReference: function() {
        if(this.postPaymentReference != '')
          request({
            url: this.$props.endPoint,
            data: qs.stringify({
              post_reference: this.postPaymentReference,
              ...this.$props.apiData
            })
          }, this, false)
          .then(response => {
            this.referenceSuccess = true
            let self = this
            setTimeout(() => {this.referenceSuccess = false}, 4000);
          })
      }
    }
  }
</script>

<style scoped lang="less">
  .parent-box {
    display: grid;
    width: 100%;
    grid-gap: 0px;
    grid-template-columns: minmax(0,1fr);
    @media (min-width: 768px) {
      grid-gap: 20px;
      grid-template-columns: minmax(0,3fr) minmax(0,1fr);  
    }
  }

  .div-button {
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .position {
    position: relative;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .parent-box {
      -ms-grid-columns: 3fr 1fr;
      display: -ms-grid;
    }
    
    .div-button {
      margin-left: 20px;
      -ms-grid-column: 2;
    }
  }
</style>