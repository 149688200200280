<template>
  <div class="token">
    <template v-if="amount">
      <p>
        <b>{{currencySymbols[currency]}}{{ amount }}</b>
      </p>
    </template>
    <template v-for="item in tokens">
      <p v-if="$props[item.prop]">
        <b class="pl27">{{item.name}}:</b> {{ $props[item.prop] }} <img :src="CopyToken" class="token-copy" v-on:click="copyClipboard(item.prop)">
      </p>
    </template>
  </div>
</template>

<script>
  import CopyToken from 'assets/img/copy-token.png'
  import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise"
  export default {
    props: {
      token: {
        type: String,
        default: null
      },
      amount: {
        type: String,
        default: null
      },
      currency: {
        type: String,
        default: null
      },
      bankAuth: {
        type: String,
        default: null
      },
      pgAuth: {
        type: String,
        default: null
      },
      currencySymbols: {
        default: () => {},
        type: Object
      },
      tokens: {
        default: () => [],
        type: Array
      }
    },
    data() {
      return {
        CopyToken: CopyToken
      }
    },
    methods: {
      copyClipboard(type) {
        clipboard.writeText(this[type])
      }
    }
  }
</script>

<style scoped lang="less">
  .token {
    color: #6A6A6A;
    font-family: Montserrat;
    font-size: 10px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 0px;
    white-space: normal;
    word-break: break-all;
    box-sizing: border-box;
    & p {
      padding: 3px 0px;
    }
    & b {
      font-weight: 700;
    }
    &-copy {
      width: 12px;
      position: relative;
      top: 2px;
      margin-top: -6px;
      margin-right: 10px;
      left: 5px;
      cursor: pointer;
    }
  }

  .pl27 {
    padding-left: 27px;
  }
</style>