<template>
  <div>
    <LoadingPG v-show="loaded" />
    <div class="parent-box">
      <div class="position">
        <InputField
          :label="title"
          placeholder="Leave Your Notes Here"
          @input="setNotes"
          :paste="true"
        />
        <SuccessField
          status="success"
          text="Note has been applied successfully"
          style="position: absolute; top: 7px; right: 85px;"
          v-show="notesSuccess"
        />
      </div>
      <div class="div-button">
        <BlueButton
          :icon-name="IconNotes"
          :disabled="!notes"
          @click="sendNotes"
        >Append</BlueButton>
      </div>
    </div>
  </div>
</template>

<script>
  import IconNotes from 'assets/img/icon-apply-notes@2x.png'
  import InputField from 'components/PayGuard/Form/InputField.vue'
  import BlueButton from 'components/PayGuard/Buttons/BlueButton.vue'
  import SuccessField from 'components/PayGuard/SuccessScreen/SuccessField.vue'
  import LoadingPG from 'components/PayGuard/LoadingPG.vue'

  import Vue from 'vue'
  import qs from 'qs'
  import { request } from 'pages/payments/payments/utils.payments.js'

  export default {
    data() {
      return {
        IconNotes: IconNotes,
        notes: '',
        loaded: false,
        notesSuccess: false
      }
    },
    components: {
      InputField,
      BlueButton,
      SuccessField,
      LoadingPG
    },
    props: {
      endPoint: {
        type: String,
        required: true,
        default: ''
      },
      apiData: {
        type: Object,
        required: false,
        default: {}
      },
      title: {
        type: String,
        default: ''
      }
    },
    methods: {
      setNotes: function(value) {
        this.notes = value
      },
      sendNotes: function() {
        if(this.notes != ''){
          request({
              url: this.$props.endPoint,
              data: qs.stringify({
                notes: this.notes,
                ...this.$props.apiData
              })
            }, this, false)
            .then(response => {
              this.notesSuccess = true
              let self = this
              setTimeout(() => {self.notesSuccess = false}, 4000);
            })
        }
      }
    }
  }
</script>

<style scoped lang="less">

.parent-box{
  display: grid;
  width: 100%;
  grid-gap: 0px;
  grid-template-columns: minmax(0,1fr);
  @media (min-width: 768px) {
    grid-gap: 20px;
    grid-template-columns: minmax(0,3fr) minmax(0,1fr);  
  }
}

.div-button{
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  padding-top: 10px;
}

.position {
  position: relative;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .parent-box {
    -ms-grid-columns: 3fr 1fr;
    display: -ms-grid;
  }
  
  .div-button {
    margin-left: 20px;
    -ms-grid-column: 2;
  }
}
</style>