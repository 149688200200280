<template>
  <div :class="['circle', color, size]">
    <div :class="['pic', size]"></div>
  </div>
</template>

<script>
  export default {
    props: {
      color: {
        default: 'green'
      },
      size: {
        type: String,
        default: 'big'
      }
    },
    methods: {
      onClick () {
        this.$emit('click')
      }
    }
  }
</script>

<style scoped lang="less">
  .circle{
    text-align: center;
    border-radius: 50%;
    display: table;
    margin: auto;
    &.big {
      height: 120px;
      width: 120px;
    }
    &.small {
      height: 40px;
      width: 40px;
    }
    .pic{
      background-size: 60px 60px;
      display: table-cell;
      vertical-align: middle;
      background-repeat: no-repeat;
      background-position: center;
      &.big {
        background-size: 60px 60px;
      }
      &.small {
        background-size: 25px 25px;
      }
    }
    &.green{
      background: #66C400;
      & .pic{
        background-image: url(~assets/img/icon-success@2x.png);
      }
    }
    &.red{
      background: #D0021B;
      & .pic{
        background-image: url(~assets/img/icon-failed@2x.png);
      }
    }
    &.yellow{
      background: #F8C41C;
      & .pic{
        background-image: url(~assets/img/icon-clock@2x.png);
      }
    }
  }
</style>