<template>
  <div>
    <LoadingPG v-show="loaded" />
    <label v-if="label != ''" class="input-label">
      {{ label }}
    </label>
    <label v-else-if="labelGap" class="input-label">&nbsp;</label>
    <div class="parent-box">
      <SuccessField
        :status="requestStatus"
        :text="requestMessage"
        style="position: absolute; bottom: 45px; right: 275px;"
        v-show="confirmationSuccess"
      />
      <InputField
        :placeholder="placeholder"
        v-model="input"
        :paste="true"
        @onEnter="send"
      />
      <div>
        <BlueButton
          :icon-name="IconSend"
          @click="send"
          :disabled="!butonEnabled"
        >Send</BlueButton>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Vuelidate from 'vuelidate'
  Vue.use(Vuelidate)

  import IconSend from 'assets/img/icon-send@2x.png'
  import InputField from 'components/PayGuard/Form/InputField.vue'
  import BlueButton from 'components/PayGuard/Buttons/BlueButton.vue'
  import LoadingPG from 'components/PayGuard/LoadingPG.vue'
  import SuccessField from 'components/PayGuard/SuccessScreen/SuccessField.vue'

  import { required } from 'vuelidate/lib/validators'

  import qs from 'qs'
  import { request } from 'pages/payments/payments/utils.payments.js'

  const validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const validatePhone = phone => {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,9}$/im
    return re.test(String(phone).toLowerCase());
  }

  export default {
    props: {
      label: {
        type: String,
        default: ''
      },
      labelGap: {
        type: Boolean,
        default: false
      },
      value: {
        type: String,
        default: ''
      },
      endPoint: {
        type: String,
        required: true,
        default: ''
      },
      apiData: {
        type: Object,
        required: false,
        default: () => {}
      },
      sendOptions: {
        type: Array,
        required: false,
        default: []
      }
    },
    data() {
      return {
        IconSend: IconSend,
        input: this.value,
        loaded: false,
        requestStatus: '',
        requestMessage: '',
        confirmationSuccess: false,
      }
    },
    components: {
      InputField,
      BlueButton,
      LoadingPG,
      SuccessField
    },
    validations: {
      input:  {
        required,
        validateFormat: function(value) {
          return (
            (
              validateEmail(value) &&
              this.sendOptions.includes('Email')
            ) || (
              validatePhone(value) &&
              this.sendOptions.includes('SMS')
            )
          )
        }
      }
    },
    computed: {
      placeholder() {
        return `Enter ` +
          `${this.sendOptions.includes('SMS') ? 'SMS number' : ''}` +
          `${this.sendOptions.length == 2 ? ' or ' : ''}` +
          `${this.sendOptions.includes('Email') ? 'email address' : ''}`
      },
      butonEnabled() {
        return (
          this.$v.input.required &&
          this.$v.input.validateFormat
        )
      }
    },
    methods: {
      send() {
        if(this.butonEnabled){
          request({
              url: this.endPoint,
              data: qs.stringify({
                ...{destination: this.input},
                ...this.apiData
              })
            }, this, false)
          .then(response => {
            if('status' in response.data) {
              this.requestStatus = response.data.status
              this.requestMessage = 'message' in response.data
                ? response.data.message
                : 'Unknown error'
              this.confirmationSuccess = true
              let self = this
              setTimeout(() => {this.confirmationSuccess = false}, 4000)
            }
          })
        }
      }
    },
    watch: {
      value: function(value) {
        this.input = value
      },
      input: function(value) {
        this.$emit('input', value)
      }
    }
  }
</script>

<style scoped lang="less">
  @import '~styles/colors.less';

  .parent-box {
    display: grid;
    grid-gap: 10px 20px;
    grid-template-columns: minmax(0,3fr) minmax(0,1fr);
    width: 100%;
    grid-column-start: auto;
    position: relative;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .parent-box {
      -ms-grid-columns: 3fr 1fr;
      display: -ms-grid;
    }
    
    .parent-box div:nth-child(5) {
      -ms-grid-column: 2;
      -ms-grid-row: 2;
      margin-left: 20px;
    }
  }

  label.input-label {
    float: left;
    width: 100%;
    padding: 10px 0;
    margin: 0 !important;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    color: @dark-blue;
    &.label-blank {
      margin-bottom: 18.67px;
    }
  } 
</style>