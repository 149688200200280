<template>
  <div class="card-form">
    <div class="card-form__inner">
      <div class="card-input">
        <label for="cardNumber" class="card-input__label">Card Number</label>
        <input
          type="text"
          id="cardNumber"
          class="card-input__input"
          v-mask="anatomyCard.mask"
          v-model="cardNumber"
          v-on:focus="focusInput"
          v-on:blur="blurInput"
          data-ref="cardNumber"
          autocomplete="off"
          placeholder="Type Card Number..."

        />
      </div>
      <!--<div class="card-input">
        <label for="cardName" class="card-input__label">Card Holders</label>
        <input type="text" id="cardName" class="card-input__input" v-model="cardName" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardName" autocomplete="off">
      </div>-->
      <div class="card-form__row">
        <div class="card-form__col">
          <div class="card-form__group">
            <label for="cardMonth" class="card-input__label">Expiry Date</label>
            
            <div class="card-input-Container">
              <div class="card-input-selectContainer">
                <select class="card-input__input -select" id="cardMonth" v-model="cardMonth" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardDate">
                  <option value="" disabled selected>Month</option>
                  <option v-bind:value="n < 10 ? '0' + n : n" v-for="n in 12" v-bind:disabled="n < minCardMonth" v-bind:key="n">
                    {{n < 10 ? '0' + n : n}}
                  </option>
                </select>
              </div>            
              
              <div class="card-input-selectContainer">
                <select class="card-input__input -select" id="cardYear" v-model="cardYear" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardDate">
                  <option value="" disabled selected>Year</option>
                  <option v-bind:value="$index + minCardYear" v-for="(n, $index) in 12" v-bind:key="n">
                    {{$index + minCardYear}}
                  </option>
                </select>
              </div>
            </div>

          </div>
        </div>
        <div class="card-form__col -cvv">
          <div class="card-input">
            <label for="cardCvv" class="card-input__label">CVV/CVC</label>
            <input type="text" class="card-input__input" id="cardCvv" v-mask="'####'" maxlength="4" v-model="cardCvv" v-on:focus="flipCard(true)" v-on:blur="flipCard(false)" autocomplete="off" placeholder="Type CVV/CVC..." />
          </div>
        </div>
      </div>
    </div>

    <div class="card-list">
      <div
        v-show="allowedBrowser"
        class="card-item" v-bind:class="{ '-active' : isCardFlipped }"
      >
        <div class="card-item__side -front">
          <div class="card-item__focus" v-bind:class="{'-active' : focusElementStyle }" v-bind:style="focusElementStyle" ref="focusElement"></div>
          <div class="card-item__cover">
            <!--<img v-bind:src="CardBG" class="card-item__bg"> -->
          </div>
          
          <div class="card-item__wrapper">
            <div class="card-item__top">
              <img v-bind:src="PGlogoTest" class="card-item__chip">
              <div class="card-item__type">
                <transition name="slide-fade-up">
                  <img v-bind:src="getLogoLink" v-if="cardType" v-bind:key="cardType" alt="" class="card-item__typeImg">
                </transition>
              </div>
            </div>
            <label for="cardNumber" class="card-item__number" ref="cardNumber">
              <template>
                <span v-for="(n, $index) in anatomyCard.mask" :key="$index">
                <transition name="slide-fade-up">
                  <div
                    v-if="cardNumber.length > $index"
                    class="card-item__numberItem"
                    :class="{ '-active' : n.trim() === '' }"
                    :key="$index"
                  >
                    {{cardNumber[$index]}}
                  </div>
                  <div
                    v-else
                    class="card-item__numberItem"
                    :class="{ '-active' : n.trim() === '' }"                      
                    :key="$index + 1"
                  >{{n}}</div>
                </transition>
              </span>
              </template>
            </label>
            <div class="card-item__content">
              <label for="cardName" class="card-item__info" ref="cardName">
                <div class="card-item__holder">Card Holder</div>
                <transition name="slide-fade-up">
                  <div class="card-item__name" v-if="cardName.length" key="1">
                    <transition-group name="slide-fade-right">
                      <span
                        class="card-item__nameItem"
                        v-for="(n, $index) in cardName.replace(/\s\s+/g, ' ')"
                        v-bind:key="$index + 1"
                      >{{n}}</span>
                    </transition-group>
                  </div>
                  <div class="card-item__name" v-else key="2">{{ cardName }}</div>
                </transition>
              </label>
              <div class="card-item__date" ref="cardDate">
                <label for="cardMonth" class="card-item__dateTitle">Expiry Date</label>
                <label for="cardMonth" class="card-item__dateItem">
                  <transition name="slide-fade-up">
                    <span v-if="cardMonth" v-bind:key="cardMonth">{{cardMonth}}</span>
                    <span v-else key="2">MM</span>
                  </transition>
                </label>/<label for="cardYear" class="card-item__dateItem">
                  <transition name="slide-fade-up">
                    <span v-if="cardYear" v-bind:key="cardYear">{{String(cardYear).slice(2,4)}}</span>
                    <span v-else key="2">YY</span>
                  </transition>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-item__side -back">
          <div class="card-item__cover"></div>
          <div class="card-item__band"></div>
          <div class="card-item__cvv">
            <div class="card-item__cvvTitle">CVV/CVC</div>
            <div class="card-item__cvvBand">
              {{cardCvv}}
            </div>
            <div class="card-item__type">
              <img v-bind:src="getLogoLink" v-if="cardType" class="card-item__typeImg">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import VueMask from 'v-mask'
  import FTlogo from 'assets/img/FT-logo-white.png'
  import PGlogo from 'assets/img/pg_logo_inverted.png'
  //import CardBG from 'assets/img/card_bg_color.jpeg'
  import CardBG from 'assets/img/card_bg.jpeg'

  import CardVisa from 'assets/img/card_alpha_visa.png'
  import CardAmex from 'assets/img/card_alpha_amex.png'
  import CardMastercard from 'assets/img/card_alpha_mastercard.png'
  import CardDiscover from 'assets/img/card_alpha_discover.png'
  import CardJCB from 'assets/img/card_alpha_jcb.png'
  import CardDiners from 'assets/img/card_alpha_diners_white.png'
  import CardMaestro from 'assets/img/card_alpha_maestro.png'

  import imgChip from 'assets/img/chip.png'
  
  import browserDetect from 'vue-browser-detect-plugin'

  Vue.use(VueMask)
  Vue.use(browserDetect)

  export default {
    props: {
      cardName: {
        type: String,
        default: 'Full name'
      },
      isEcom: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
        minCardYear: new Date().getFullYear(),
        isCardFlipped: false,
        focusElementStyle: null,
        isInputFocused: false,
        FTlogo: FTlogo,
        imgChip: imgChip,
        CardBG: CardBG,
        PGlogoTest: PGlogo
      };
    },
    mounted() {
      if(!this.isEcom)
        document.getElementById("cardNumber").focus()
    },
    computed: {
      allowedBrowser() {
        return (
          this.$browserDetect.isChrome ||
          this.$browserDetect.isFirefox ||
          this.$browserDetect.isOpera ||
          this.$browserDetect.isSafari ||
          this.$browserDetect.isEdge
        )
      },
      anatomyCard() {
        switch(this.cardType) {
          case 'amex':
            return {
              mask: '#### ###### #####',
              maskHideLength: 15
            }
          case 'dc':
            return {
              mask: '#### ###### ####',
              maskHideLength: 14
            }
          default:
            return {
              mask: '#### #### #### ####',
              maskHideLength: 14
            }
        }
      },
      cardType() {
        if(!this.cardNumber)
          return 'fusion'

        const number = this.cardNumber.replace(/\s/g,'')

        if (number.match(new RegExp('^4')) != null)
          return 'visa'

        if (number.match(new RegExp('^(34|37)')) != null)
          return 'amex'

        if (number.match(new RegExp('^5[1-5]')) != null)
          return 'mastercard'

        if (number.match(new RegExp('^6011')) != null)
          return 'discover'

        if (number.match(new RegExp('^(30[0-5]|309|36|38|39|54|55)')) != null)
          return 'dc'

        if (number.match(new RegExp('^(?:2131|1800|35[0-9]{3})')) != null)
          return 'jcb'

        if (number.match(new RegExp('^(5018|5020|5038|6304|6759|6761|6763)')) != null)
          return 'maestro'
        
        if (number.match(new RegExp('^9')) != null)
          return 'test'


        return 'fusion'
      },
      getLogoLink() {
        switch(this.cardType) {
          case 'visa':
            return CardVisa
          case 'amex':
            return CardAmex
          case 'mastercard':
            return CardMastercard
          case 'discover':
            return CardDiscover
          case 'jcb':
            return CardJCB
          case 'dc':
            return CardDiners
          case 'maestro':
            return CardMaestro
          default:
            return ''
        }
      },
      minCardMonth() {
        return this.cardYear === this.minCardYear
          ? new Date().getMonth() + 1
          : 1
      }
    },
    watch: {
      cardNumber() {
        this.cardNumber = this.format(this.cardData().cardNumber.replace(/\s/g, ''), this.anatomyCard.mask)
        return this.$emit("cardData", this.cardData())
      },
      cardYear() {
        if (this.cardMonth < this.minCardMonth) {
          this.cardMonth = ''
        }
        return this.$emit("cardData", this.cardData())
      },
      cardMonth() {
        return this.$emit("cardData", this.cardData())
      },
      cardCvv() {
        return this.$emit("cardData", this.cardData())
      }
    },
    methods: {
      format(value, mask) {
        const numberString = String(value);
        let result = '';
        let j = 0;

        for (let i = 0; i < mask.length && j < numberString.length; i++) {
          if (mask[i] === '#') {
            result += numberString[j++];
          } else {
            result += mask[i];
          }
        }
        return result;
      },
      cardData() {
        return {
          cardNumber: this.cardNumber,
          cardYear: this.cardYear,
          cardMonth: this.cardMonth,
          cardCvv: this.cardCvv
        }
      },
      flipCard(status) {
        this.isCardFlipped = status;
      },
      focusInput(e) {
        this.isInputFocused = true;
        let targetRef = e.target.dataset.ref;
        let target = this.$refs[targetRef];
        this.focusElementStyle = {
          width: `${target.offsetWidth}px`,
          height: `${target.offsetHeight}px`,
          transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
        }
      },
      blurInput() {
        let vm = this;
        setTimeout(() => {
          if (!vm.isInputFocused) {
            vm.focusElementStyle = null;
          }
        }, 300);
        vm.isInputFocused = false;
      }
    }
  }
</script>

<style scoped lang="less">
  @import '~styles/colors.less';

  body {
    background: #ddeefc;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
  }
  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }

  .card-form {
    padding: 0px 15px 50px 15px;
    max-width: 570px;
    margin: auto;
    width: 100%;

    @media screen and (max-width: 576px) {
      margin: 0 auto;
    }

    &__inner {
      background: #fff;
      padding: 35px;
      padding-top: 0px;

      @media screen and (max-width: 480px) {
        padding: 25px;
      }
      @media screen and (max-width: 375px) {
        padding: 0px;
      }
    }

    &__row {
      display: flex;
      align-items: flex-start;
      @media screen and (max-width: 480px) {
        flex-wrap: wrap;
      }
    }

    &__col {
      flex: auto;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 480px) {
        margin-right: 0;
        flex: unset;
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.-cvv {
        max-width: 150px;
        @media screen and (max-width: 480px) {
          max-width: initial;
        }
      }
    }

    &__group {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .card-input__input {
        flex: 1;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .card-item {
    max-width: 430px;
    height: 270px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
    width: 100%;

    @media screen and (max-width: 480px) {
      max-width: 310px;
      height: 220px;
      width: 90%;
      margin-top: 20px;
    }

    @media screen and (max-width: 375px) {
      height: 180px;
      width: 100%;
    }

    &.-active {
      .card-item__side {
        &.-front {
          transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
        }
        &.-back {
          transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
          // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
        }
      }
    }

    &__focus {
      position: absolute;
      z-index: 3;
      border-radius: 5px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
      opacity: 0;
      pointer-events: none;
      overflow: hidden;
      border: 2px solid rgba(255, 255, 255, 0.65);

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: rgb(8, 20, 47);
        height: 100%;
        border-radius: 5px;
        filter: blur(25px);
        opacity: 0.5;
      }

      &.-active {
        opacity: 1;
      }
    }

    &__side {
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.3);
      transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
      transform-style: preserve-3d;
      transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
      backface-visibility: hidden;
      height: 100%;

      &.-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
        z-index: 2;
        padding: 0;
        // background-color: #2364d2;
        // background-image: linear-gradient(
        //   43deg,
        //   #4158d0 0%,
        //   #8555c7 46%,
        //   #2364d2 100%
        // );
        height: 100%;

        .card-item__cover {
          transform: rotateY(-180deg)
        }
      }
    }
    &__bg {
      max-width: 100%;
      display: block;
      max-height: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    &__cover {
      height: 100%;
      position: absolute;
      height: 100%;
      background-image: linear-gradient(#2B295E, #BF087F);
      left: 0;
      top: 0;
      width: 100%;
      border-radius: 15px;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(6, 2, 29, 0.3);
      }
    }

    &__top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 40px;
      padding: 0 10px;

      @media screen and (max-width: 480px) {
        margin-bottom: 25px;
      }
      @media screen and (max-width: 375px) {
        margin-bottom: 15px;
      }
    }

    &__chip {
      width: 60px;
      @media screen and (max-width: 480px) {
        width: 50px;
      }
      @media screen and (max-width: 375px) {
        width: 40px;
      }
    }

    &__type {
      height: 45px;
      position: relative;
      display: flex;
      justify-content: flex-end;
      max-width: 100px;
      margin-left: auto;
      width: 100%;

      @media screen and (max-width: 480px) {
        height: 40px;
        max-width: 90px;
      }
      @media screen and (max-width: 375px) {
        height: 30px;
      }
    }

    &__typeImg {
      max-width: 100%;
      object-fit: contain;
      max-height: 100%;
      object-position: top right;
    }

    &__info {
      color: #fff;
      width: 100%;
      max-width: calc(100% - 90px);
      padding: 10px 10px;
      font-weight: 500;
      display: block;

      cursor: pointer;

      @media screen and (max-width: 480px) {
        padding: 10px;
      }
    }

    &__holder {
      opacity: 0.7;
      font-size: 13px;
      margin-bottom: 6px;
      @media screen and (max-width: 480px) {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }

    &__wrapper {
      font-family: "Source Code Pro", monospace;
      padding: 25px 15px;
      position: relative;
      z-index: 4;
      height: 100%;
      text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
      user-select: none;
      @media screen and (max-width: 480px) {
        padding: 20px 10px;
      }
    }

    &__name {
      font-size: 18px;
      line-height: 1;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }
    &__nameItem {
      display: inline-block;
      min-width: 8px;
      position: relative;
    }

    &__number {
      font-weight: 500;
      line-height: 1;
      color: #fff;
      font-size: 27px;
      margin-bottom: 35px;
      display: inline-block;
      padding: 10px 15px;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        font-size: 21px;
        margin-bottom: 15px;
        padding: 10px 10px;
      }

      @media screen and (max-width: 375px) {
        font-size: 19px;
        margin-bottom: 10px;
        padding: 10px 10px;
      }
    }

    &__numberItem {
      width: 16px;
      display: inline-block;
      &.-active {
        width: 30px;
      }

      @media screen and (max-width: 480px) {
        width: 13px;

        &.-active {
          width: 16px;
        }
      }

      @media screen and (max-width: 375px) {
        width: 12px;

        &.-active {
          width: 8px;
        }
      }
    }

    &__content {
      color: #fff;
      display: flex;
      align-items: flex-start;
    }

    &__date {
      flex-wrap: wrap;
      font-size: 18px;
      margin-left: 0px;
      padding: 10px 0 0 0;
      display: inline-flex;
      width: 80px;
      white-space: nowrap;
      flex-shrink: 0;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }

    &__dateItem {
      position: relative;
      span {
        width: 22px;
        display: inline-block;
      }
    }

    &__dateTitle {
      opacity: 0.7;
      font-size: 13px;
      width: 100%;
      margin-bottom: 3px;

      @media screen and (max-width: 480px) {
        font-size: 12px;
      }
    }
    &__band {
      background: rgba(0, 0, 19, 0.8);
      width: 100%;
      height: 50px;
      margin-top: 30px;
      position: relative;
      z-index: 2;
      @media screen and (max-width: 480px) {
        margin-top: 20px;
      }
      @media screen and (max-width: 375px) {
        height: 40px;
        margin-top: 10px;
      }
    }

    &__cvv {
      text-align: right;
      position: relative;
      z-index: 2;
      padding: 15px;
      .card-item__type {
        opacity: 0.7;
      }

      @media screen and (max-width: 375px) {
        padding: 10px 15px;
      }
    }
    &__cvvTitle {
      padding-right: 10px;
      font-family: "Source Code Pro", monospace;
      font-size: 15px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 5px;
    }
    &__cvvBand {
      font-family: "Source Code Pro", monospace;
      height: 45px;
      background: #fff;
      margin-bottom: 30px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      color: #1a3b5d;
      font-size: 18px;
      border-radius: 4px;
      box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

      @media screen and (max-width: 480px) {
        height: 40px;
        margin-bottom: 20px;
      }

      @media screen and (max-width: 375px) {
        margin-bottom: 15px;
      }
    }
  }

  .card-list {
    margin-bottom: -130px;
    padding-bottom: 90px;
    padding-left: 35px;
    padding-right: 35px;

    @media screen and (max-width: 480px) {
      margin-bottom: -120px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 120px;
    }
  }

  .card-input-Container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr);
    width: 100%;
  }

  .card-input-selectContainer {
    position: relative;
    &:before {
      background-repeat: no-repeat;
      content: "";
      display: block;
      height: 8px;
      left: auto;
      margin: auto;
      position: absolute;
      right: 10px;
      bottom: 15px;
      width: 14px;
      pointer-events: none;
      background-image: url(~assets/img/icon-down@2x.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .card-input {
    margin-bottom: 20px;
    &__label {
      font-size: 16px;
      font-weight: 600;
      color: @dark-blue;
      width: 100%;
      display: block;
      user-select: none;
      line-height: 150%;
      font-family: "Open Sans", sans-serif;
    }
    &__input {
      width: 100%;
      height: 40px;
      border-radius: 3px;
      box-shadow: none;
      border: 1px solid #c7c7c7;
      transition: all 0.3s ease-in-out;
      font-size: 14px;
      font-weight: normal;
      padding: 4px 10px;
      background: none;
      box-sizing: border-box;
      color: #6a6a6a;
      font-family: "Open Sans", sans-serif;

      &::placeholder {
        font-style: italic;
        color: #c7c7c7;
      }

      &:hover,
      &:focus {
        border-color: #c7c7c7;
      }

      &:focus {
        box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
      }

      &.-select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        font-style: italic;
        background: rgba(255, 255, 255, 0);
        background: -webkit-linear-gradient(rgba(255, 255, 255, 0), #e6f2ff);
        background: -o-linear-gradient(rgba(255, 255, 255, 0), #e6f2ff);
        background: -moz-linear-gradient(rgba(255, 255, 255, 0), #e6f2ff);
        background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%);
        -webkit-border-radius: 3px !important;
        -moz-border-radius: 3px !important;
        -ms-border-radius: 3px !important;
        -o-border-radius: 3px !important;
        border-radius: 3px;
        border: 1px solid #C7C7C7;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        height: 40px;
        padding: 4px 35px 4px 10px;
        width: 100%;
        color: #2B295E;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        box-sizing: border-box;
      }
    }
  }

  .slide-fade-up-enter-active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
    position: relative;
  }
  .slide-fade-up-leave-active {
    transition: all 0.25s ease-in-out;
    position: absolute;
  }
  .slide-fade-up-enter {
    opacity: 0;
    transform: translateY(15px);
    pointer-events: none;
  }
  .slide-fade-up-leave-to {
    opacity: 0;
    transform: translateY(-15px);
    pointer-events: none;
  }

  .slide-fade-right-enter-active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
    position: relative;
  }
  .slide-fade-right-leave-active {
    transition: all 0.25s ease-in-out;
    position: absolute;
  }
  .slide-fade-right-enter {
    opacity: 0;
    transform: translateX(10px) rotate(45deg);
    pointer-events: none;
  }
  .slide-fade-right-leave-to {
    opacity: 0;
    transform: translateX(-10px) rotate(45deg);
    pointer-events: none;
  }
</style>