<template>
  <div :class="['form-group-pg', position == 'center' || position == 'right' ? 'ml-1' : '']">
    <label v-if="label != ''" class="input-label">
      {{ label }}
    </label>
    <label v-else-if="labelGap" class="input-label">&nbsp;</label>
    <cleave
      v-if="this.$props.type in options"
      :class="[position, 'input', error ? 'error' : '', disabled? 'disabled':'', bold ? 'bold':'']"
      :placeholder="placeholder"
      :options="getOptions()"
      :name="name"
      v-model="input"
      :readonly="readonly"
      @blur.native="addDecimals"
      @keyup.native="keyup"
    />
    <input
      v-else
      :class="[
        position,
        'input',
        error ? 'error' : '',
        disabled ? 'disabled' : '',
        bold ? 'bold' : '',
        paste ? 'bigger-padding' : '',
        copy ? 'bigger-padding' : ''
      ]"
      :placeholder="placeholder"
      :name="name"
      v-model="input"
      :maxlength="this.$props.maxLength"
      type="text"
      @blur.native="addDecimals"
      v-on:keyup.enter="onEnter"
      :readonly="readonly"
      ref="mainInput"
    >
    <img
      v-if="paste"
      :src="IconPaste"
      :class="[ showClipboard ? 'iconClipboard' : 'hide-icon']"
      @click="pasteClipboard"
    />    
    <img
      v-if="copy"
      :src="IconCopy"
      :class="[ showClipboard ? 'iconClipboard' : 'hide-icon']"
      @click="copyClipboard"
    />
    <div v-if="error" class="error-message">{{ errorMessage }}</div>
  </div>
</template>


<script>
import Cleave from "vue-cleave-component";
import CleavePhone from "cleave.js/dist/addons/cleave-phone.gb.js";
import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise";
import IconPaste from "assets/img/icon-paste@2x.png";
import IconCopy from "assets/img/icon-copy@2x.png";

export default {
  components: {
    Cleave,
    CleavePhone
  },
  props: {
    type: {
      type: String,
      default: null
    },
    maxLength: {
      type: Number,
      default: 255
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    labelGap: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    maxValue: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      default: "Type..."
    },
    name: {
      type: String,
      default: ""
    },
    showError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: "Required"
    },
    position: {
      type: String,
      default: ""
    },
    width: {
      type: Number,
      default: 100
    },
    requiredField: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    paste: {
      type: Boolean,
      default: false
    },
    copy: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      input: this.value,
      error: this.showError,
      showClipboard: false,
      IconPaste: IconPaste,
      IconCopy: IconCopy,
      options: {
        currency: {
          prefix: this.prefix,
          numeral: true,
          noImmediatePrefix: true,
          rawValueTrimPrefix: true,
          numeralPositiveOnly: true,
          numeralDecimalScale: 2,
          numeralIntegerScale: this.$props.maxLength,
          numeralThousandsGroupStyle: "none"
        },
        numeric: {
          numeral: true,
          numeralPositiveOnly: true,
          numeralDecimalScale: 0,
          numeralIntegerScale: this.$props.maxLength,
          numeralThousandsGroupStyle: "none"
        },
        percentage: {
          prefix: "",
          numeral: true,
          noImmediatePrefix: true,
          rawValueTrimPrefix: true,
          numeralPositiveOnly: true,
          numeralDecimalScale: 2,
          numeralIntegerScale: this.$props.maxLength,
          numeralThousandsGroupStyle: "none"
        },
        phone: {
          phone: true,
          phoneRegionCode: "GB"
        },
        date: {
          date: true,
          datePattern: ['m', 'y'],
          delimiter: '/'
        },
        creditCard:{
          creditCard: true,
          delimiter: ' ',
        }
      }
    };
  },
  beforeMount() {
    if (navigator.clipboard && navigator.clipboard.readText) { //detect if past from clipboard is supported
      this.showClipboard = true;
    }
  },
  watch: {
    prefix: function(value) {
      this.options.currency.prefix = value
    },
    showError: function(value) {
      this.error = value;
    },
    value: function(value) {
      this.setMaxValue(value);
    },
    input: function(value) {
      this.setMaxValue(value);
      this.$emit("input", this.input);
      this.error =
        value == "" && this.$props.requiredField && this.$props.showError
          ? true
          : false;
    }
  },
  methods: {
    addDecimals() {
      if (this.type == "currency" && this.input)
        this.input = Number(this.input).toFixed(2);
    },
    getOptions() {
      if (this.$props.type in this.options)
        return this.options[this.$props.type];
      return {};
    },
    setMaxValue(value) {
      let newValue = value;
      if (
        ["currency", "numeric", "percentage"].includes(this.$props.type) &&
        this.$props.maxValue &&
        Number(value) > this.$props.maxValue
      )
        newValue = this.$props.maxValue;
      this.input = newValue;
    },
    setFocus() {
      this.$refs.mainInput.focus()
    },
    keyup(e) {
      this.$emit("keyup", e);
    },
    onEnter() {
      this.$emit("onEnter")
    },
    pasteClipboard() {
      clipboard
        .readText()
        .then(text => {
          this.input = text;
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log("Something went wrong", err);
        });
    },
    copyClipboard() {
      clipboard.writeText(this.input);
    }
  }
};
</script>

<style scoped lang="less">
  @import '~styles/colors.less';

  .form-group-pg {
    position: relative;
    margin-bottom: 10px;
  }

  .form-group-pg.ml-1 {
    margin-left: -1px;
    & .input {
      border-left-color: transparent !important;
    }
  }

  .input {
    z-index: 0;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 10px;
    font-family: "Open sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #2B295E;
    background: transparent;
    border: 1px solid #c7c7c7;
    border-radius: 3px;
    &.bold {
      font-weight: bold;
    }
    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    }
    &.right {
      border-radius: 0 3px 3px 0;
    }
    &.left {
      border-radius: 3px 0 0 3px;
    }
    &.center {
      border-radius: 0 0 0 0;
    }
    &.error {
      border-color: #d0021b;
    }
    &:focus {
      outline: none !important;
    }
  }

  .bigger-padding {
    padding-right: 35px;
  }

  label.input-label {
    float: left;
    width: 100%;
    padding: 10px 0;
    margin: 0 !important;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    color: @dark-blue;
    &.label-blank {
      margin-bottom: 18.67px;
    }
  } 

  .iconClipboard {
    position: absolute;
    bottom: 12px;
    width: 15px;
    cursor: pointer;
    right: 10px;
    display: initial;
  }

  ::-webkit-input-placeholder {
    font-style: italic;
    font-weight: normal;
    color: #c7c7c7;
  }

  ::-moz-placeholder {
    font-style: italic;
    font-weight: normal;
    color: #c7c7c7;
  }

  :-ms-input-placeholder {
    font-style: italic;
    font-weight: normal;
    color: #c7c7c7;
  }

  :-moz-placeholder {
    font-style: italic;
    font-weight: normal;
    color: #c7c7c7;
  }

  .error-message {
    position: relative;
    top: -71px;
    left: calc(~"50% - 50px");
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100px;
    height: 35px;
    padding: 9px 5px 8px 5px;
    margin-bottom: -35px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    line-height: 125%;
    color: white;
    text-align: center;
    background-color: #d0021b;
    border: 1px solid #d0021b;
    border-radius: 3px;
    -webkit-box-shadow: 0 -2px 6px 0 rgba(208, 2, 27, 0.3);
    box-shadow: 0 -2px 6px 0 rgba(208, 2, 27, 0.3);
    &::after {
      position: absolute;
      top: 28px;
      left: calc(~"50% - 5.5px");
      width: 11px;
      height: 11px;
      color: transparent;
      content: ".";
      background: #d0021b;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .hide-icon {
    display: none;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .hide-icon {
      position: absolute;
      bottom: 12px;
      width: 15px;
      cursor: pointer;
      right: 10px;
      display: initial;
    }
  }
</style>