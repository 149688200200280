<template>
  <button
    v-bind:class="['btn-pink', disabled ? 'disabled' : '']"
    @click="onClick"
    :title="title"
    v-bind:style="{ 'background-image': 'url(' + iconName + ')' }"
  >
    <i
      v-if="fontAwesome"
      :class="fontAwesome"
      style="float: left;"
    ></i><slot></slot>
  </button>
</template>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      fontAwesome: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      onClick () {
        this.$emit('click');
      }
    }
  }
</script>

<style scoped lang="less">
  @import '~styles/colors.less';

  .btn-pink {
    height: 40px;
    width: 100%;
    border: 1px solid @pink;
    border-radius: 3px;
    background-color: @pink;
    cursor: pointer;
    color: #FFFFFF;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: 6px center;
    padding: 6px 8px 6px 16px;
    box-sizing: border-box;
    & span{
        text-align: center;
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
</style>