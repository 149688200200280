import Vue from 'vue'
import VueRouter from 'vue-router'
import Notifications from 'vue-notification'
import VModal from 'vue-js-modal'
import App from './app.vue'

Vue.config.devtools = process.env.NODE_ENV === 'development' ? true : false
Vue.config.debug = process.env.NODE_ENV === 'development' ? true : false
Vue.config.silent = process.env.NODE_ENV === 'development' ? false : true

import mainScreen from 'pages/payments/paylink/app.vue'
//const mainScreen = () => import('pages/payments/paylink/app.vue')

Vue.use(VueRouter)
Vue.use(Notifications)
Vue.use(VModal)

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: mainScreen, name: 'paylink_main' }
  ]
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app_paylink')
