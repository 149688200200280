import axios from 'axios'

const client = axios.create()

const request = function (
  options,
  self,
  message=false,
  messageText='Operation succesfully',
) {

  const setLoaded = function (value) {
    if (self && 'loaded' in self)
      self.loaded = value
  }

  const onSuccess = function (response) {
    if (message && 'status' in response.data)
      if (self && self.$notify && response.data.status == 'success')
        self.$notify({
          text: messageText
        })
    else if(self && self.$notify)
      self.$notify({
        text: 'An error has occurred'
      })
    setLoaded(false)
    return response
  }

  const onError = function (error) {
    if(self && self.$notify)
      self.$notify({
        text: 'An error has occurred'
      })
    console.log(error)
    setLoaded(false)
    return Promise.reject(error.response || error.message || error);
  }

  const generalOptions = {
    headers: {
      'content-type': 'contentType' in options ? options.contentType : 'application/x-www-form-urlencoded'
    },
    method: 'POST'
  }

  setLoaded(true)

  return client(Object.assign({}, generalOptions, options))
    .then(onSuccess)
    .catch(onError)
}

export default request
