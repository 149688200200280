<template>
  <div class="card-form">
    <button
      :class="[
        'card-form__button',
        !stateSubmitButton ? 'card-form__button-disabled' : ''
      ]"
      :disabled="!stateSubmitButton"
      @click="click"
    >{{buttonName}}</button>
  </div>
</template>

<script>
  export default {
    props: {
      formData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        forcedStateButton: 'disabled'
      }
    },
    computed: {
      buttonName() {
       return this.formData.namespace === "tokenise"
        ? this.formData.transaction_type === 'ecom' ? 'Tokenise' : 'Send'
        : this.formData.transaction_type === 'ecom' ? 'Submit' : 'Send';
      },
      verifyCard() {
        const number = this.formData.cardNumber.replace(/\s/g, '')
        const expressions = {
          'visa': '^4[0-9]{12}(?:[0-9]{3})?$',
          'amex': '^3[47][0-9]{13}$',
          'mastercard': '^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$',
          'discover': '^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$',
          'dc': '^3(?:0[0-5]|[68][0-9])[0-9]{11}$',
          'jcb': '^(?:2131|1800|35[0-9]{3})[0-9]{11}$',
          'maestro': '^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$',
          'test': '^9[0-9]{12}(?:[0-9]{3})?$'

        }
        for(const e of Object.values(expressions))
          if (number.match(new RegExp(e)) != null)
            return true
      },
      verifyCVVLength() {
        return this.formData.cardCvv.replace(/\s/g,'').length > 2
      },
      stateSubmitButton() {
        //if(this.forcedStateButton == 'enabled')
        // this.formData.ecom == true
        return (
          this.formData.cardNumber &&
          this.verifyCard &&
          this.formData.cardMonth &&
          this.formData.cardYear &&
          this.formData.cardCvv &&
          this.verifyCVVLength
        )
      }
    },
    methods: {
      setForcedStateButton(value) {
        this.forcedStateButton = value
      },
      click() {
        this.$emit('action', 'submit')
      }
    }
  }
</script>

<style scoped lang="less">
  @import '~styles/colors.less';
  .card-form {
    padding: 0px 15px 50px 15px;
    max-width: 570px;
    margin: auto;
    width: 100%;

    @media screen and (max-width: 576px) {
      margin: 0 auto;
    }

    &__button {
      width: 100%;
      height: 55px;
      background: @pink;
      border: none;
      border-radius: 3px;
      font-size: 22px;
      font-weight: 500;
      font-family: "Open Sans", sans-serif;
      color: #fff;
      margin: 45px auto 0px auto;
      cursor: pointer;
      position: relative;
      max-width: 160px;
      text-align: center;
      padding: 5px 10px 5px 20px;
      display: block;

      @media screen and (max-width: 480px) {
        margin: 40px auto 0px auto;
      }
      
      &:before {
        font-family: "Font Awesome 5 Free"; 
        font-weight: 900; 
        content: "\f058";
        position: absolute;
        left: 15px
      }
    }

    &__button-disabled {
      opacity: 0.4;
    }
  }
</style>