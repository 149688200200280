<template>
  <div :class="['form-group-pg']">
    <label v-if="label != ''" class="input-label">
      {{ label }}
    </label>
    <vue-suggestion
      :placeholder="placeholder"
      :items="items"
      v-model="address"
      :setLabel="setLabel"
      :itemTemplate="itemTemplate"
      @changed="inputChange"
      @selected="itemSelected"
      @blur="itemTyped"
    />
    <img
      :src="IconPaste"
      :class="[ showClipboard ? 'iconClipboardGetAddress' : 'hide-icon']"
      @click="pasteClipboard"
    />    
  </div>
</template>

<script>
  import Vue from 'vue'
  import VueSuggestion from 'vue-suggestion'
  Vue.use(VueSuggestion)

  import itemTemplate from './InputGetAddressTemplate.vue'
  import { request } from 'payments/utils.payments.js'
  import IconPaste from "assets/img/icon-paste@2x.png"
  import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise"

  export default {
    props: {
      label: {
        type: String,
        default: ''
      },
      type_api: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: "Type..."
      },
    },
    data () {
      return {
        address: '',
        selectedItem: {},
        items: [],
        itemTemplate,
        IconPaste: IconPaste,
        showClipboard: false
      }
    },
    beforeMount() {
      if (navigator.clipboard && navigator.clipboard.readText) { //detect if past from clipboard is supported
        this.showClipboard = true;
      }
    },
    methods: {
      buildAddress(data) {
        return [data["line_1"], data["line_2"], data["line_3"], data["line_4"]].filter(n => n).join(", ")
      },
      itemSelected(item) {
        request({
          url: `${basePath}payments/api/key/${this.type_api}get-address?id=${item.id}&token=${typeof token != 'undefined' ? token : ''}`,
          method: 'GET'
        })
        .then(response => {
          if(response.data) {
            this.address = {"name": this.buildAddress(response.data)}
            this.selectedItem = response.data
            this.$emit("addressData", this.selectedItem)
            if(document.getElementsByClassName('vs__list').length > 0)
              document.getElementsByClassName('vs__list')[0].style.display = 'none'
          }
        })
        .catch(e => {
          Vue.notify('Oops! An error occurred. Please try again later')
          this.loaded = false
          console.log(e)
        })
      },
      setLabel(item) {
        return item.name;
      },
      itemTyped(item) {
        this.$emit("addressData", {
          ...this.selectedItem,
          ...{line_1: item}
        })
      },
      inputChange(text) {
        request({
          url: `${basePath}payments/api/key/${this.type_api}autocomplete-address?term=${text}&token=${typeof token != 'undefined' ? token : ''}`,
          method: 'GET'
        })
        .then(response => {
          this.items = response.data
        })
        .catch(e => {
          Vue.notify('Oops! An error occurred. Please try again later')
          this.loaded = false
          console.log(e)
        })
      },
      pasteClipboard() {
        clipboard
          .readText()
          .then(text => {
            this.address = {"name": text}
            this.inputChange(text)
            this.itemTyped(text)
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log("Something went wrong", err);
          });
      },
    }
  }

</script>

<style scoped lang="less">
  @import '~styles/colors.less';

  .form-group-pg {
    position: relative;
    margin-bottom: 10px;
  }

  label.input-label {
    float: left;
    width: 100%;
    padding: 10px 0;
    margin: 0 !important;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    color: @dark-blue;
    &.label-blank {
      margin-bottom: 18.67px;
    }
  }

  .iconClipboardGetAddress {
    position: absolute;
    bottom: 12px;
    width: 15px;
    cursor: pointer;
    right: 10px;
    display: initial;
  }

  .hide-icon {
    display: none;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .hide-icon {
      position: absolute;
      bottom: 12px;
      width: 15px;
      cursor: pointer;
      right: 10px;
      display: initial;
    }
  }
</style>

<style lang="less">

  .vs__input {
    z-index: 0;
    box-sizing: border-box !important;
    width: 100%;
    height: 40px;
    padding: 10px;
    font-family: "Open sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #2B295E;
    background: transparent;
    border: 1px solid #c7c7c7;
    border-radius: 3px;
    &:focus {
      outline: none !important;
    }
  }

  ::-webkit-search-cancel-button{
    position:relative;
    right:20px;
  }

  ::-webkit-input-placeholder {
    font-style: italic;
    font-weight: normal;
    color: #c7c7c7;
  }

  ::-moz-placeholder {
    font-style: italic;
    font-weight: normal;
    color: #c7c7c7;
  }

  :-ms-input-placeholder {
    font-style: italic;
    font-weight: normal;
    color: #c7c7c7;
  }

  :-moz-placeholder {
    font-style: italic;
    font-weight: normal;
    color: #c7c7c7;
  }

  .vue-suggestion .vs__list {
    width: 100%;
    text-align: left;
    border: none;
    border-top: none;
    max-height: 400px;
    width: 532px;
    overflow-y: auto;
    border-top: 1px solid #c7c7c7;
    border-bottom: 1px solid #c7c7c7;
    position: relative;
    z-index: 999;
  }
  .vue-suggestion .vs__list .vs__list-item {
    background-color: #fff;
    padding: 10px;
    border-left: 1px solid #c7c7c7;
    border-right: 1px solid #c7c7c7;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #2B295E;
  }
  .vue-suggestion .vs__list .vs__list-item:last-child {
    border-bottom: none;
  }
  .vue-suggestion .vs__list .vs__list-item:hover {
    background-color: #eee !important;
  }
  .vue-suggestion .vs__list,
  .vue-suggestion .vs__loading {
    position: absolute;
  }
  .vue-suggestion .vs__list .vs__list-item {
    cursor: pointer;
  }
  .vue-suggestion .vs__list .vs__list-item.vs__item-active {
    background-color: #f3f6fa;
  }
</style>