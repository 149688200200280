<template>
  <div class="cover-page div-loading-pic">
    <img :src="PayGuardAnimation" class="loading-pic"/>
  </div>
</template>

<script>
  import PayGuardAnimation from 'assets/img/PayGuard-Animation.gif'
  export default {
    data() {
      return {
        PayGuardAnimation: PayGuardAnimation
      }
    },
    methods: {
      onClick () {
        this.$emit('click');
      }
    }
  }
</script>
<style scoped>
  .cover-page{
    background: rgba(255, 255, 255, 0.5);
    position:fixed;
    padding: 0px;
    margin: 0px;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999 !important;
  }
  
  .loading-pic{
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%,-50%);
  }
</style>