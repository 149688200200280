<template>
  <div class="Paylink__result__body">
    <div v-if="code == '404'">
      <center>
        <StatusCircle color="red" />
        <p class="Paylink__result__errorText" tabindex="0">
          The link has expired. Please request a new one.
        </p>
      </center>
    </div>
    <div v-else-if="code == 'success'">
      <center>
        <StatusCircle color="green" />
        <p class="Paylink__result__successText">
          Thank you. <br/><br/>
          We will confirm your transaction shortly.
        </p>
      </center>
    </div>
    <div v-else-if="code == 'error'">
      <center>
        <StatusCircle color="red" />
        <p tabindex="0" class="Paylink__result__header">An error has occurred</p>
        <p tabindex="0" v-if="message" class="Paylink__result__errorText">{{message}}</p>
      </center>
    </div>
  </div>
</template>

<script>
  import StatusCircle from 'components/PayGuard/StatusCircle.vue'
  
  import PayGuardLogo from 'assets/img/pay-guard-logo-new.jpg'
  import PayGuardLocker from 'assets/img/locked-padlock.svg'

  export default {
    data() {
      return {
        formData: {},
        PayGuardLogo: PayGuardLogo,
        PayGuardLocker: PayGuardLocker
      }
    },
    props: {
      code: {
        type: String,
        default: '404'
      },
      message: {
        type: String,
        default: ''
      },
      company: {
        type: String,
        default: ''
      }
    },
    components: {
      StatusCircle
    }
  }
</script>

<style scoped lang="less">
  
  @import '~styles/colors.less';
  
  .Paylink {
    &__result {
      &__body {
        padding-top: 30px;
      }

      &__header {
        color: @dark-blue;
        font-family: "Open Sans",sans-serif;
        font-size: 20px;
        padding-top: 35px;
        font-weight: 600;
      }
      
      &__errorText {
        color: #d0021b;
        font-size: 16px;
        font-family: 'Open sans', sans-serif;
        position: relative;
        font-weight: 600;
        padding-top: 20px;
      }
      
      &__successText {
        color: #66c400;
        font-size: 16px;
        font-family: 'Open sans', sans-serif;
        position: relative;
        font-weight: 600;
        padding-top: 20px;
      }
    }
  }
</style>