var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['form-group-pg', _vm.position == 'center' || _vm.position == 'right' ? 'ml-1' : '']},[(_vm.label != '')?_c('label',{staticClass:"input-label aa"},[_vm._v(_vm._s(_vm.label))]):(_vm.labelGap)?_c('label',{staticClass:"input-label"},[_vm._v(" ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"select-wrap"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],class:[
        _vm.position,
        'select',
        _vm.error ? 'error' : '',
        _vm.disabled ? 'disabled' : '',
        _vm.italic ? 'italic' : ''
      ],style:(_vm.style),attrs:{"title":_vm.title},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.input=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[(_vm.nullable)?_c('option',{domProps:{"value":null}},[_vm._v("Choose an Option")]):_vm._e(),_vm._v(" "),_vm._l((_vm.options),function(option,index){return _c('option',{key:'opt' + index,domProps:{"value":option.value || option}},[_vm._v("\n        "+_vm._s(option.name || option)+"\n      ")])}),_vm._v(" "),_vm._l((_vm.optgroups),function(option,index){return _c('optgroup',{key:'group' + index,attrs:{"label":option.name}},_vm._l((option.items),function(item,idx){return _c('option',{key:'groupopt' + idx,domProps:{"value":item.value || item}},[_vm._v("\n          "+_vm._s(item.name || item)+"\n        ")])}),0)})],2)]),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"error-message"},[_vm._v("\n    "+_vm._s(_vm.error_message)+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }