<template>
  <div class="Paylink" v-if="showPage != ''">
    <notifications
      position="top center"
      classes="Notifications--style"
      :duration="8000"
      :max="3"
    />
    <LoadingPG v-show="loaded" />
    <div class="threeDS" v-if="threeDSDisplayType !== ''">
      <div class="formThreeDS" v-if="threeDSDisplayType === 'form'">
        <form id="silentPost" :action="infoThreeDS.threeDSURL" method="post">
          <input type="hidden" v-for="(value, key) in infoThreeDS.threeDSRequest" :name="key" :value="value"/>
          <noscript><input type="submit" value="Continue"/></noscript>
        </form>
      </div>
      <div class="iframeThreeDS" v-if="threeDSDisplayType === 'iframe'">
          <iframe name="threeds_acs" height="1" width="1" style="display: none;">
              <form id="collectionForm" method="POST" :action="infoThreeDS.threeDSURL" target="threeds_acs">
                  <input type="hidden" v-for="(value, key) in infoThreeDS.threeDSRequest" :name="key" :value="value"/>
              </form>
          </iframe>
      </div>
      <div class="stringThreeDS" v-if="threeDSDisplayType === 'string'">
        <div v-if="iframe3DSString != ''" v-html="iframe3DSString"></div>
      </div>
    </div>


    <div v-if="showPage != ''">
      <div class="Paylink__header">
        <div class="Paylink__items">
          <img :src="PayGuardLogo" alt="PayGuard logo" class="Paylink__header__logo" />
        </div>
        <div class="Paylink__header__locker">
          <div class="Paylink__header__locker__icon">
            <img :src="PayGuardLocker" alt="Locker icon" class="Paylink__header__locker__icon__image" />
          </div>
          <p class="Paylink__header__locker__text" tabindex="0">
            We use secure transmission & encryption to protect your data.
          </p>
        </div>
      </div>
      <Result
        v-show="showPage == 'result'"
        class="Paylink__body"
        :code="code"
        :message="message"
        :company="formData.customer_name"
      />

      <div
        v-show="showPage == 'resultECOM'"
        class="Paylink__body"
      >
        <FormResult
          :stateLink="stateLink"
          :groupId="groupId"
          :data="data"
          :paymentErrors="paymentErrors"
          :agentCurrencies="agentCurrencies"
          :namespace="formData.namespace"
          @action="buttonAction"
        />
      </div>
      <div
        v-show="showPage == 'form'"
        class="Paylink__body"
      >
        <div class="Paylink__data">
          <div class="Paylink__group">
            <label class="Paylink__company">{{formData.customer_name}}</label>
          </div>
          <div
            v-if="todayPayments.length === 1"
            class="Paylink__group"
          >
            <label
                class="Paylink__amounts"
                :key="`amount#${0}`"
              >Amount Due Today: <span>{{todayPayments[0].summary}}</span></label>
          </div>
          <div
              v-if="scheduledPayments.length > 0"
            class="Paylink__group"
          >
            <label class="Paylink__amounts">Scheduled Payments:</label>
            <table>
              <thead>
                <tr>
                  <th>Payment Dates</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in visibleRows" :key="index" :class="{ 'odd-row': index % 2 === 0, 'even-row': index % 2 !== 0}">
                    <td>{{ item.date }}</td>
                    <td>{{ item.summary }}</td>
                </tr>
                <tr v-if="scheduledPayments.length > 2">
                    <td colspan="2">
                      <div class="dropdown-button" @click="toggleTable">
                        <img :src="DropDownArrow" :class="{ 'up': showTable}" />
                        {{ showTable ? 'Hide' : 'Show All' }}
                        <img :src="DropDownArrow" :class="{ 'up': showTable}" />
                      </div>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="Paylink__fields">
          <div class="Paylink__cardName">
            <FormCustomer
              v-if="formData.transaction_type == 'ecom'"
              :permissions="permissions"
              @customerData="setCustomerData"
            />
            <FormCreditCard
              :cardName="formData.card_holder"
              :isEcom="formData.transaction_type == 'ecom'"
              @cardData="setCardData"
            />
            <FormSend
              :formData="formData"
              @action="buttonAction"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FormCustomer from './FormCustomer.vue'
  import FormCreditCard from './FormCreditCard.vue'
  import FormSend from './FormSend.vue'
  import DropDownArrow from 'assets/img/icon-down@2x.png'
  //import FormConfirm from './FormConfirm.vue'
  import FormResult from './FormResult.vue'
  import Result from './Result.vue'
  import PayGuardLogo from 'assets/img/PayGuard-logo-color-new.png'
  import PayGuardLocker from 'assets/img/locked-padlock.svg'
  import IconDone from 'assets/img/icon-done.png'
  import footer from 'assets/img/powered-by-fusion.png'
  import PinkButton from 'components/PayGuard/Buttons/PinkButton.vue'
  import InputField from 'components/PayGuard/Form/InputField.vue'
  import LoadingPG from 'components/PayGuard/LoadingPG.vue'

  import '@fortawesome/fontawesome-free/css/all.css'
  import '@fortawesome/fontawesome-free/js/all.js'

  import request from 'libs/request.js'
  import qs from 'qs'
  import utils from 'libs/utils.js'
  import paymentUtils from 'payments/utils.payments.js'
  import {isToday} from "date-fns";

  const paymentConfig = {
    pic: {
      payment: 'wallet',
      scheduled: 'calendar',
      preautorisation: 'locked'
    },
    color: {
      completed: 'green',
      scheduled: 'green',
      error: 'red',
    },
    messageSuccess: {
      payment: 'Received Successfully',
      scheduled: 'Scheduled Successfully',
      preauthorisation: 'Pre-authorised Successfully'
    },
    messageFailed: {
      payment: 'Payment Declined',
      scheduled: 'Failed to Schedule',
      preauthorisation: 'Pre-authorised Failed'
    }
  }

  export default {
    data() {
      return {
        formData: {},
        data: {},
        paymentErrors: [],
        permissions: [],
        stateLink: 'paymentSuccess',
        groupId: null,
        PayGuardLogo: PayGuardLogo,
        PayGuardLocker: PayGuardLocker,
        footer: footer,
        IconDone: IconDone,
        loaded: false,
        showPage: '',
        code: '',
        message: '',
        agentCurrencies: [],
        redirectUrl: '',
        iframe3DSString: '',
        threeDSDisplayType: '',
        infoThreeDS: {},
        jwtoken:"",
        scheduledPayments:[],
        todayPayments:[],
        showTable:false,
        DropDownArrow: DropDownArrow,
      }
    },
    components: {
      FormCustomer,
      FormCreditCard,
      FormSend,
      //FormConfirm,
      FormResult,
      Result,
      PinkButton,
      InputField,
      LoadingPG,
      DropDownArrow
    },
      mounted() {
         window.addEventListener('message', this.eventMessage)
      },
    created() {
      if(typeof token !== 'undefined') {
        request({
          url: `${basePath}payments/api/key/paymentlink/capture?token=${encodeURIComponent(token)}&is_redirect=${encodeURIComponent(this.$route.query.is_redirect)}`,
          method: 'GET'
        }, this)
        .then(response => {
          if(
            response.data &&
            'status' in response.data &&
            response.data['status'] === 'success'
          )
          {
            if(
              "is_redirect" in response.data &&
              response.data["is_redirect"] === true
            ) {
              if (
                  "is_3ds" in response.data &&
                  response.data["is_3ds"] === true
              ) {
                this.formData = response.data.data;
                if (["cardstream","first_data"].includes(this.formData.gateway_type)) {
                  this.showPage = "3DS";
                  this.goSilentPost(response.data['info_3ds']);
                }
              }
              else {
                this.goToSuccess(response.data);
              }
              return;
            }
            this.formData = response.data.data
            if (this.formData.gateway_type === "ecospend"){
              window.location.href = this.formData.paylink_url
              return
            }
            if (this.formData.gateway_type === "worldpay_wpg"){
                this.jwtoken = response.data.jwtoken
            }
            this.agentCurrencies = response.data.agent_currencies

            if(this.formData.photo_base64)
              this.PayGuardLogo = this.formData.photo_base64

            if(this.formData.all_transactions) {
               let today = [];
               let scheduled = [];
               for (const item of this.formData.all_transactions) {
                 const date = utils.utcToTimezone(item.date,"D MMM YYYY" ,Intl.DateTimeFormat().resolvedOptions().timeZone);
                 if(this.isToday(date)){
                   item.date = date;
                   today.push(item);
                 }
                 else{
                   item.date = date;
                   scheduled.push(item);
                 }
               }
               this.todayPayments = this.getUniqueTansactions(today);
               this.scheduledPayments = this.getUniqueTansactions(scheduled);
            }
            this.showPage = 'form'

            if(
              response.data &&
              'permissions' in response.data
            )
              this.permissions = response.data.permissions


            return
          }

          if(
            response.data &&
            'status' in response.data &&
            'is_redirect' in response.data &&
            response.data['status'] === 'error' &&
            response.data['is_redirect'] === true
          )
            this.goToError("3DS challenge was not passed")

          if(
            response.data &&
            'photo_base64' in response.data &&
            response.data.photo_base64
          )
            this.PayGuardLogo = response.data.photo_base64

          this.go404()
        })
        .catch(e => {
          this.go404()
        })
      } else {
        this.go404()
      }
    },
    methods: {
        toggleTable(){
          this.showTable = !this.showTable;
        },
        isToday(date){
          const today = new Date()
          return utils.formatDate(today, 'D MMM YYYY') == date;
        },
        eventMessage(event){
            if (event.origin === "https://secure-test.worldpay.com") {
                var data = JSON.parse(event.data);
                if (data !== undefined && data.Status) {
                    this.formData.session_id = data.SessionId;
                    this.submitECOM()
                    // Extract the value of SessionId for onward processing.
                }
            }
        },
      setCustomerData(data) {
        this.formData = {
          ...this.formData,
          ...{
            card_holder: data.card_holder,
            address1: data.address1,
            town: data.town,
            state: data.state,
            postcode: data.postcode,
            country: data.country
          }
        }
      },
      setCardData(data) {
        this.formData = {
          ...this.formData,
          ...{
            cardNumber: data.cardNumber,
            cardYear: data.cardYear,
            cardMonth: data.cardMonth,
            cardCvv: data.cardCvv
          }
        }
      },
      setCardNumber(value) {
        this.formData = {
          ...this.formData,
          ...{cardNumber: value}
        }
      },
      go404() {
        this.showPage = 'result'
        this.code = '404'
      },
      buttonAction(action, data) {
        switch(action) {
          case 'form':
            this.showPage = 'form'
            break
          /*case 'confirm':
            this.confirm()
            break*/
          case 'submit':
            this.submit()
            break
          case 'result':
            this.code = data
            this.showPage = 'result'
            break
        }
      },
      /*confirm() {
        this.showPage = 'confirm'
      },*/
      submit() {
        if(typeof token !== 'undefined') {
          this.loaded = true
          if(this.formData.transaction_type == 'ecom') {
              if(this.formData.gateway_type == 'worldpay_wpg'){
                  this.threeDSDisplayType = 'iframe';
                  this.infoThreeDS = {
                      'threeDSURL' : 'https://secure-test.worldpay.com/shopper/3ds/ddc.html',
                      'threeDSRequest': {
                          'Bin' : this.formData.cardNumber.replace(/\s/g,''),
                          'JWT' :  this.jwtoken,
                      }
                  }
                  this.$nextTick(() => {
                      window.setTimeout('document.forms.collectionForm.submit()', 0);
                  })
              }
              else
                  this.submitECOM()
          }
          else
            this.submitMOTO()
        }
      },
      submitMOTO() {
        request({
          url: `${basePath}payments/api/key/paymentlink/capture`,
          method: 'POST',
          data: qs.stringify({
            token: token,
            cardNumber: this.formData.cardNumber.replace(/\s/g,''),
            cardMonth: this.formData.cardMonth,
            cardYear: String(this.formData.cardYear).slice(2,4),
            cardCvv: this.formData.cardCvv.replace(/\s/g,'')
          }),
        })
        .then(response => {
          if(
            response.data &&
            'status' in response.data &&
            response.data['status'] === 'success'
          ) {
            this.loaded = false
            this.code = response.data['status']
            this.message = response.data['message']
            this.showPage = 'result'
            return
          }
          this.go404()
        })
      },
      urlECOM(){
        return this.formData.namespace === "tokenise"
            ? `${basePath}payments/api/key/paymentlink/card/tokenise`
            : `${basePath}payments/api/key/submit-paymentlink`
      },
      submitECOM() {
        request({
            url: this.urlECOM(),
            data: {
              paylink_token: token,
              payment_method: {
                card_holder: this.formData.card_holder,
                address1: this.formData.address1,
                town: this.formData.town,
                postcode: this.formData.postcode,
                state: this.formData.state,
                country: this.formData.country,
                cc_num: this.formData.cardNumber,
                expiry_date: `${this.formData.cardMonth}${this.formData.cardYear.toString().slice(-2)}`,
                cvv: this.formData.cardCvv,
                session_id: this.formData.session_id
              }
            }
          })
          .then(response => {
            if (response.data) {
              if('status' in response.data && response.data.status == "pending authorisation") {
                let info = this.formData.namespace === "tokenise" ? response.data.tokenise_status : response.data.schedule_statuses[0]
                if ('info_3ds' in info) {
                  if (["opayo", "worldpay_wpg", "cardstream"].includes(this.formData.gateway_type)) {
                    this.goSilentPost(info.info_3ds);
                  } else if (this.formData.gateway_type === "first_data") {
                    if (info.info_3ds.threeds_data_type === "iframe") {
                        this.threeDSDisplayType = 'string';
                        this.iframe3DSString = info.info_3ds.threeds_data;
                        this.$nextTick(() => {
                          document.getElementById("tdsMmethodForm").submit();
                        })
                    }
                    else if (info.info_3ds.threeds_data_type === "challenge"){
                        this.goSilentPost(info.info_3ds.threeds_data)
                    }
                  } 
                }
                this.setScheduleData(response.data.schedule_statuses)
                this.handleCardAction(response.data.schedule_statuses[0])
              } else if('status' in response.data && ['success', 'succeeded'].includes(response.data.status)) {
                this.goToSuccess(response.data)
              } else {
                const message = 'message' in response.data && response.data.message
                  ? response.data.message
                  : 'Unknown error'
                this.goToError(message)
              }
            }
          })
      },
      handleCardAction(data) {
        if(data.type_intent == "PaymentIntent") {
          stripe.handleCardAction(
            data.client_secret
          ).then(result => this.handleStripeJsResult(result, data))
        } else if(data.type_intent == "SetupIntent") {
          stripe.confirmCardSetup(
            data.client_secret
          ).then(result => this.handleStripeJsResult(result, data))
        } else if (data.redirect_url !== undefined) {
          this.redirectUrl = data.redirect_url
          window.location.href = this.redirectUrl
        }
      },
      handleStripeJsResult(result, data) {
        if (result.error) {
          this.setPaymentError(result.error, data.type_intent)
          this.goToError(result.error.message)
        } else {
          this.confirmResult(result, data.type_intent)
        }
      },
      setPaymentError(error, type_intent) {
        const intent_id = type_intent == 'PaymentIntent' && 'payment_intent' in error && error.payment_intent && 'id' in error.payment_intent
          ? error.payment_intent.id
          : type_intent == 'SetupIntent' && 'setup_intent' in error && error.setup_intent && 'id' in error.setup_intent
          ? error.setup_intent.id
          : null

        request({
            url: `${basePath}payments/api/key/set-payment-error`,
            data: qs.stringify({
              paylink_token: token,
              intent_id: intent_id,
              message: error.message
            })
          })
      },
      confirmResult(result, type_intent) {
        let data = {
              paylink_token: token,
              is_pxp_check: true
        }
        if (type_intent === null)
          data.intent_id = null
        else if (type_intent === "PaymentIntent")
          data.intent_id = result.paymentIntent.id
        else
          data.intent_id = result.setupIntent.id
        request({
            url: `${basePath}payments/api/key/submit-paymentlink`,
            data: data
          })
          .then(response => {
            if (response.data) {
              if('status' in response.data && response.data.status == 'success') {
                this.goToSuccess(response.data)
              } else if('message' in response.data && response.data.message) {
                const message = 'message' in response.data && response.data.message
                  ? response.data.message
                  : 'Unknown error'
                this.goToError(message)
              }
            }
          })
      },
      setScheduleData(schedule_statuses) {
        this.data = this.paymentStatusesGroupedByCurrency(
          this.getStatuses(schedule_statuses)
        )
      },
      goSilentPost(data){
        this.threeDSDisplayType = 'form';
        this.infoThreeDS = data;
        this.$nextTick(() => {
          window.setTimeout('document.forms.silentPost.submit()', 0);
        })
      },
      goToSuccess(data) {
        this.groupId = data.group_id
        if (this.formData.namespace && 'data' in data && 'namespace' in  data.data ) {
          this.formData.namespace = data.data.namespace
        }
        if (this.formData.namespace === "tokenise" ){
          this.data = "Tokenised Successfully"
        } else {
          this.setScheduleData(data.schedule_statuses)
        }
        this.showPage = 'resultECOM'
        this.loaded = false
      },
      goToError(message) {
        this.stateLink = 'paymentFailed'
        this.paymentErrors = [message]
        this.showPage = 'resultECOM'
        this.loaded = false
      },
      getStatuses(rows) {
        if(Array.isArray(rows) == false)
          return []
        return rows
          .map(e => {
            const _type = paymentUtils.typePayment(e.call_date, e.date)
            const pic = _type in paymentConfig.pic ? paymentConfig.pic[_type] : 'wallet'
            const color = e.status in paymentConfig.color ? paymentConfig.color[e.status] : 'green'
            let message = ''
            if(color == 'red')
              message = _type in paymentConfig.messageFailed ? paymentConfig.messageFailed[_type] : ''
            else
              message = _type in paymentConfig.messageSuccess ? paymentConfig.messageSuccess[_type] : ''
            return Object.assign(e, {
              amount: Number(e.transaction_amount),
              type: _type,
              pic: pic,
              color: color,
              message: message
            })
          })
      },
      paymentStatusesGroupedByCurrency(statuses) {
        const grouped = {}
        statuses.forEach((e, i) => {
          if(!(e.type in grouped))
            grouped[e.type] = {}

          if(!(e.currency in grouped[e.type])) {
            grouped[e.type][e.currency] = utils.pickFromObject(e, ['amount', 'color', 'pic', 'message'])
            grouped[e.type][e.currency].tokens = []
          } else {
            grouped[e.type][e.currency].amount += parseFloat(e.amount)
          }

          if(e.token)
            grouped[e.type][e.currency].tokens.push({
              amount: e.transaction_amount,
              currency: e.currency,
              token: e.token,
              bank_auth: e.bank_auth,
              pg_auth: e.pg_auth
            })
        })
        return grouped
      },
      getUniqueTansactions(transactions) {
        const aggregatedData = {};
        for (const item of transactions) {
            const date = item.date;
            if (!aggregatedData[date]) {
              aggregatedData[date] = [];
            }
            const existingItem = aggregatedData[date].find(i => i.symbol === item.symbol);
            if (existingItem) {
              existingItem.amount += item.amount;
            } else {
              aggregatedData[date].push({symbol: item.symbol, amount: item.amount});
            }
        }
        return Object.entries(aggregatedData).map(([date, summary]) => {
          const formattedSummary = summary.map(item => `${item.symbol}${item.amount.toFixed(2)}`).join(", ");
          return { date, summary: formattedSummary };
        });
      }
    },
    computed: {
      visibleRows() {
        return this.showTable ? this.scheduledPayments : this.scheduledPayments.slice(0, 2);
      },
    }
  }
</script>

<style scoped lang="less">
  @import '~styles/colors.less';

  .Paylink {
    float: left;
    max-width: 550px;
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
    clear: both;
    @media (min-width: 590px) {
      margin: 20px;
    }


    &__header {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: minmax(0,120px) minmax(0,1fr);
      box-sizing: border-box;
      width: 100%;
      padding: 0px 50px;
      align-items: center;
      padding-top: 20px;
      @media screen and (max-width: 480px) {
        padding: 0px;
        grid-template-columns: minmax(0,1fr);
      }

      &__locker {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: minmax(0,40px) minmax(0,1fr);
        @media screen and (max-width: 480px) {
          grid-template-columns: minmax(0,40px) minmax(0,1fr);
        }

        &__icon {
          text-align: right;
          color: #666;
          @media screen and (max-width: 480px) {
            padding-top: 0px;
          }

          &__image {
            width: 40px;
            max-height: 60px;
          }
        }

        &__text {
          text-align: left;
          color: #888;
          font-family: 'Open Sans', sans-serif;
          font-size: 12px;
          font-weight: bold;
          max-width: 170px;
          @media (min-width: 992px) {
            font-size: 14px;
            max-width: 100%;
          }
        }
      }

      &__logo {
        width: auto;
        max-width: 100%;
        max-height: 95px;
        max-width: 95px;
        align-self: center;
      }
    }

    &__data {
      max-width: 410px;
      padding: 20px 50px 0px 50px;
      width: 100%;
      float: left;
      @media screen and (max-width: 480px) {
        padding: 20px 0px 0px 0px;
      }
    }

    &__group {
      float: left;
      width: 100%;
      margin-bottom: 15px;
    }

    &__company {
      font-size: 25px;
      font-weight: 600;
      color: @dark-blue;
      width: 100%;
      display: block;
      user-select: none;
      line-height: 150%;
      font-family: 'Open Sans', sans-serif;
      @media screen and (max-width: 480px) {
        font-size: 20px;
      }
    }

    &__amounts {
      font-size: 15px;
      font-weight: 600;
      color: @dark-blue;
      width: 100%;
      display: block;
      user-select: none;
      line-height: 150%;
      font-family: 'Open Sans', sans-serif;
      @media screen and (max-width: 480px) {
        font-size: 15px;
      }
      & span {
        color: @grey;
        font-weight: normal;
      }
    }

    &__items {
      text-align: center;
      color: #666;
      font-size: 65px;
      justify-self: baseline;
      width: 95px;
      height: 95px;
      display: grid;
      justify-self: center;
    }

    &__input {
      width: 100%;
      height: 38px;
      border-radius: 2px;
      box-shadow: none;
      border: 1px solid @light-blue;
      transition: all 0.3s ease-in-out;
      font-size: 15px;
      padding: 4px 10px;
      background: none;
      opacity: 0.5 !important;
      color: #6a6a6a;
      font-family: "Open Sans", sans-serif;
    }

    &__cardsContainer {
      width: 100%;
      float: left;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      padding-bottom: 10px;
      padding-top: 35px;
    }

    &__card {
      width: 100%;
      height: 40px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      &.visa { background-image: url(~assets/img/visa_card.png); }
      &.mastercard { background-image: url(~assets/img/visa_mastercard.png); }
      &.maestro { background-image: url(~assets/img/card_maestro.png); }
      &.americanexpress { background-image: url(~assets/img/card_americanexpress.png); }
    }

    &__title {
      text-align: left;
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
      color: @light-blue;
      font-weight: 600;
    }

    &__body {
      width: 100%;
      float: left
    }

    &__text {
      font-family: 'Montserrat';
      color: @dark-blue;
      font-size: 16px;
      line-height: 125%;
    }

    &__fields {
      width: 100%;
      float: left;
      padding-bottom: 10px;
    }

    &__cardName {
      grid-column: span 2;
    }

    &__btnContainer {
      width: calc(100% / 3 - 5px);
      float: right;
      margin-bottom: 35px
    }

    &__footer {
      padding-top: 20px;
      text-align: center;
      margin-left: 20px;
      margin-right: 20px;
      max-width: 550px;
      width: 100%;
    }

    &__footerIimg {
      width: 80px;
    }
  }

  table {
    width: 100%;
  }
  table, th, td {
    border: 1px solid #A9CAE9;
    border-collapse: collapse;
  }

  tr > td, tr > th, .dropdown-button {
    color: @dark-blue;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    cursor: pointer;
    padding: 2px 8px;
    text-align: left;
  }

  .dropdown-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .odd-row {
    background-color: rgba(125, 176, 226, 0.1);
  }
  .even-row {
    background-color: @white;
  }
  .up {
    transform: rotate(180deg);
  }


  </style>

<style scoped lang="less">
  @import '~styles/notifications.less';
</style>